<template>
  <div :class="['signFormBox']" @click.self="closePreview">
    <div :class="['signWrap']">
      <vue-scroll :ops="ops" @handle-scroll="handleAutoCloseSelect">
        <div class="entryFormBox">
          <header v-if="baseInfo.show_head_img+''==='1'">
            <img v-if="!baseInfo.form_cover" src="../images/formHeader.png" />
            <img v-else :class="`signWrap__header-${coverImageMode}`" :src="coverUrl" />
          </header>
          <div v-if="isBilingual" style="margin: 10px 0 0 10px">
            <vh-radio-group
              v-model="languageType"
              @change="handleLanguageTypeChange"
              size="small"
              text-color="#fff"
              fill="#FB3A32"
            >
              <vh-radio-button :label="1">中文</vh-radio-button>
              <vh-radio-button :label="2">英文</vh-radio-button>
            </vh-radio-group>
            <span style="font-size: 14px; color: #666; vertical-align: middle">
              当前切换按钮仅生效与预览效果，实际观看端无此按钮显示
            </span>
          </div>

          <article>
            <h1 class="pageTitle">
              <pre>{{ baseInfo.form_title }}</pre>
            </h1>
            <div
              ref="intro"
              v-if="baseInfo.form_introduce"
              :class="['pageIntro', overflowStatus == 1 ? 'pageIntroEllipsis' : '']"
            >
              <pre>{{ baseInfo.form_introduce }}</pre>
              <span @click="handleUnfold(2)" class="textTail" v-show="overflowStatus == 1">
                <span class="isEllipsis">...</span>
                {{ '展开' }}
              </span>
              <span @click="handleUnfold(1)" class="textTail" v-show="overflowStatus == 2">
                <span class="isEllipsis"></span>
                {{ '收起' }}
              </span>
            </div>
            <div class="tabsBox">
              <div :class="['tabs', baseInfo.theme_color]">
                <div :class="{ active: tabs == 1 }" @click="tabs = 1">
                  {{ baseInfo.form_tab_register_title }}
                </div>
                <div :class="{ active: tabs == 2 }" @click="tabs = 2">
                  {{ baseInfo.form_tab_verify_title }}
                </div>
              </div>
            </div>
            <!-- 报名表单 -->
            <template>
              <vh-form v-show="tabs === 1" :model="form" class="entryForm" ref="form">
                <template v-for="(question, quesIndex) in list">
                  <vh-form-item
                    :key="'qa' + question.id + quesIndex"
                    :prop="question.id + ''"
                    v-show="question.type != 6"
                    :class="question.is_must == 1 ? 'is-required' : ''"
                    :label="
                      question.subject === '隐私声明'
                        ? ''
                        : `${quesIndex < 9 ? `0${quesIndex + 1}` : quesIndex + 1}.${
                            question.subject
                          }`
                    "
                  >
                    <template slot="label">
                      <pre>{{
                        question.subject === '隐私声明'
                          ? ''
                          : `${quesIndex < 9 ? `0${quesIndex + 1}` : quesIndex + 1}.${
                              question.subject
                            }`
                      }}</pre>
                    </template>
                    <!-- 输入框 -->
                    <template
                      v-if="
                        (question.type === 0 && question.default_type !== 4) || question.type === 1
                      "
                    >
                      <vh-input
                        v-if="question.type == 0 && question.default_type == 2"
                        :maxlength="question.type == 0 ? '' : 60"
                        :show-word-limit="question.type != 0"
                        v-model.number.trim="form[question.id]"
                        type="number"
                        autocomplete="off"
                        :placeholder="
                          question.options
                            ? JSON.parse(question.options).placeholder
                            : placeholderList[question.options] || '请输入'
                        "
                      ></vh-input>
                      <vh-input
                        v-else
                        :maxlength="question.type == 0 ? '' : 60"
                        :show-word-limit="question.type != 0"
                        v-model.trim="form[question.id]"
                        autocomplete="off"
                        :placeholder="getPlaceholder(question)"
                      ></vh-input>
                    </template>
                    <!-- 单选 -->
                    <template v-if="question.default_type === 4 || question.type === 2">
                      <vh-radio-group v-model="form[question.id]">
                        <template v-if="question.default_type === 4">
                          <div v-for="(gender, i) of question.items" :key="i">
                            <vh-radio :label="gender.subject" name="gender"></vh-radio>
                          </div>
                        </template>
                        <template v-else>
                          <div
                            v-for="(radioItem, radioItemIndex) in question.items"
                            :key="'radio' + radioItem.id + radioItemIndex"
                          >
                            <vh-radio
                              :label="'' + radioItem.id + radioItemIndex"
                              :name="question.id + ''"
                            >
                              <pre>{{ radioItem.subject }}</pre>
                            </vh-radio>
                            <template v-if="radioItem.type === 1">
                              <vh-input
                                :maxlength="60"
                                autocomplete="off"
                                show-word-limit
                                placeholder="请输入描述内容"
                                v-show="form[question.id] === '' + radioItem.id + radioItemIndex"
                                style="margin-top: 10px"
                                v-model.trim="form[`${question.id}${radioItem.id}`]"
                                class="radioInput"
                              ></vh-input>
                            </template>
                          </div>
                        </template>
                      </vh-radio-group>
                    </template>
                    <!-- 多选 -->
                    <template v-if="question.type === 3">
                      <vh-checkbox-group v-model="checkboxs">
                        <div
                          v-for="(checkItem, checkItemIndex) in question.items"
                          :key="'checkbox' + checkItem.id + quesIndex + checkItemIndex"
                        >
                          <vh-checkbox :label="'' + checkItem.id + checkItemIndex">
                            <pre>{{ checkItem.subject }}</pre>
                          </vh-checkbox>
                          <template v-if="checkItem.type === 1">
                            <vh-input
                              :maxlength="60"
                              show-word-limit
                              placeholder="请输入描述内容"
                              autocomplete="off"
                              v-if="checkboxs.some(id => id == '' + checkItem.id + checkItemIndex)"
                              style="margin-top: 10px"
                              v-model.trim="form[`${question.id}${checkItem.id}`]"
                              class="radioInput"
                            ></vh-input>
                          </template>
                        </div>
                      </vh-checkbox-group>
                    </template>
                    <!-- 下拉 -->
                    <template v-if="question.type === 4">
                      <vh-select
                        ref="autoCloseRefFlag"
                        class="question-of-select"
                        v-model="form[question.id]"
                        :placeholder="languageType == 2 ? 'Please choose' : '请选择'"
                        :popper-append-to-body="false"
                      >
                        <vh-option
                          v-for="(option, optionIndex) in question.items"
                          :key="'autoCloseRefFlag' + option.id + optionIndex + ''"
                          :label="option.subject"
                          :value="option.subject"
                        >
                          <pre>{{ option.subject }}</pre>
                        </vh-option>
                      </vh-select>
                    </template>
                    <!-- 地域选择 -->
                    <template v-if="question.type === 5">
                      <vh-row :gutter="20">
                        <vh-col :span="question.colNum">
                          <vh-input
                            v-show="false"
                            v-model.trim="form[question.id]"
                            autocomplete="off"
                          ></vh-input>
                          <vh-select
                            ref="autoCloseRefFlag"
                            v-model="province"
                            @change="regionalChange('province')"
                            :placeholder="
                              languageType === 2 ? 'Please select province' : '请选择省份'
                            "
                          >
                            <vh-option
                              v-for="opt in provinces"
                              :key="opt.value"
                              :label="opt.label"
                              :value="opt.value"
                            ></vh-option>
                          </vh-select>
                        </vh-col>
                        <vh-col v-if="question.options.show_city == 1" :span="question.colNum">
                          <vh-select
                            ref="autoCloseRefFlag"
                            v-model="city"
                            @change="regionalChange('city')"
                            :placeholder="languageType === 2 ? 'Please select a city' : '请选择市'"
                          >
                            <vh-option
                              v-for="opt in cityList"
                              :key="opt.value"
                              :label="opt.label"
                              :value="opt.value"
                            ></vh-option>
                          </vh-select>
                        </vh-col>
                        <vh-col v-if="question.options.show_district == 1" :span="question.colNum">
                          <vh-select
                            ref="autoCloseRefFlag"
                            v-model="county"
                            @change="regionalChange('county')"
                            :placeholder="
                              languageType === 2 ? 'Please select district/county' : '请选择区/县'
                            "
                          >
                            <vh-option
                              v-for="opt in countyList"
                              :key="opt.value"
                              :label="opt.label"
                              :value="opt.value"
                            ></vh-option>
                          </vh-select>
                        </vh-col>
                      </vh-row>
                    </template>
                    <template v-if="question.type === 7">
                      <SubjectLives
                        ref="subjectLives"
                        :isBilingual="isBilingual"
                        :languageType="languageType"
                        :isCheckallFlag="true"
                        @getCheckedIds="
                          ids => {
                            getCheckedIds(ids, question.id);
                          }
                        "
                      ></SubjectLives>
                    </template>
                  </vh-form-item>
                  <vh-form-item
                    class="verifyCodeBox"
                    v-if="isPhoneValidate && question.type == 0 && question.default_type == 2"
                    :key="'phoneValidate' + question.id + quesIndex"
                    :required="false"
                    prop="code"
                  >
                    <vh-row :gutter="20">
                      <vh-col :span="12">
                        <div id="setCaptcha" class="captcha">
                          <vh-input v-model.trim="form.imgCode" autocomplete="off"></vh-input>
                          <!-- <p class="errorText" v-show="errorMsgShow">图形码错误</p> -->
                        </div>
                      </vh-col>
                      <vh-col :span="12">
                        <vh-input
                          v-model.trim="form.code"
                          autocomplete="off"
                          :placeholder="languageType === 2 ? 'Verification code' : '请输入验证码'"
                        >
                          <vh-button :disabled="true" size="mini" slot="append" v-preventReClick>
                            {{ languageType === 2 ? 'Get code' : '发送验证码' }}
                          </vh-button>
                        </vh-input>
                      </vh-col>
                    </vh-row>
                  </vh-form-item>
                  <vh-form-item
                    class="verifyCodeBox"
                    v-if="isEmailValidate && question.type == 0 && question.default_type == 3"
                    :key="'emailValidate' + question.id + quesIndex"
                    :required="false"
                    prop="code"
                  >
                    <vh-row :gutter="20">
                      <vh-col :span="12">
                        <div id="setCaptchaEmail" class="captcha">
                          <vh-input v-model.trim="form.imgCodeEmail" autocomplete="off"></vh-input>
                        </div>
                      </vh-col>
                      <vh-col :span="12">
                        <vh-input
                          v-model.trim="form.codeEmail"
                          autocomplete="off"
                          placeholder="请输入验证码"
                        >
                          <vh-button :disabled="true" size="mini" slot="append">
                            发送验证码
                          </vh-button>
                        </vh-input>
                      </vh-col>
                    </vh-row>
                  </vh-form-item>
                </template>
                <vh-form-item class="provicy-item" v-if="provicy" :prop="provicy.id + ''">
                  <!-- 隐私声明 -->
                  <template>
                    <div class="previewPrivacy-content">
                      <vh-checkbox
                        class="checkbox"
                        v-model="form[provicy.id]"
                        v-if="provicy.is_must"
                      ></vh-checkbox>
                      <pre class="pre-html" v-html="provicyText"></pre>
                    </div>
                  </template>
                </vh-form-item>
                <div class="btnBox">
                  <vh-button
                    :disabled="true"
                    :class="[baseInfo.theme_color, 'length152']"
                    round
                    type="primary"
                  >
                    立即报名
                  </vh-button>
                </div>
                <!-- 隐私协议合规 -->
                <privacy-select scene="signForm" v-if="!provicy" compType="2"></privacy-select>
              </vh-form>
            </template>

            <!-- 验证 -->
            <template>
              <vh-form v-show="tabs === 2" :model="verifyForm" ref="verifyForm" class="entryForm">
                <vh-form-item
                  label="请输入报名时您填写的手机号"
                  prop="phone"
                  :class="'is-required'"
                  v-show="validType == 'phone'"
                >
                  <vh-input
                    type="number"
                    v-model.number.trim="verifyForm.phone"
                    autocomplete="off"
                    :placeholder="phoneItem.options && phoneItem.options.placeholder"
                  ></vh-input>
                </vh-form-item>
                <vh-form-item
                  class="verifyCodeBox"
                  v-show="validType == 'phone' && isPhoneValidate"
                  prop="code"
                >
                  <vh-row :gutter="20">
                    <vh-col :span="12">
                      <div id="setCaptcha1" class="captcha">
                        <vh-input v-model.trim="verifyForm.imgCode" autocomplete="off"></vh-input>
                      </div>
                    </vh-col>
                    <vh-col :span="12">
                      <vh-input
                        v-model.trim="verifyForm.code"
                        autocomplete="off"
                        placeholder="验证码"
                      >
                        <vh-button :disabled="true" size="mini" slot="append">发送验证码</vh-button>
                      </vh-input>
                    </vh-col>
                  </vh-row>
                </vh-form-item>
                <vh-form-item
                  label="请输入报名时您填写的邮箱"
                  prop="email"
                  :class="'is-required'"
                  v-show="validType == 'email'"
                >
                  <vh-input
                    type="text"
                    v-model.trim="verifyForm.email"
                    autocomplete="off"
                    :placeholder="emailItem.options && emailItem.options.placeholder"
                  ></vh-input>
                </vh-form-item>
                <vh-form-item
                  class="verifyCodeBox"
                  v-show="validType == 'email' && isEmailValidate"
                  prop="code"
                >
                  <vh-row :gutter="20">
                    <vh-col :span="12">
                      <div id="setCaptchaEmail1" class="captcha">
                        <vh-input
                          v-model.trim="verifyForm.imgCodeEmail"
                          autocomplete="off"
                        ></vh-input>
                      </div>
                    </vh-col>
                    <vh-col :span="12">
                      <vh-input
                        v-model.trim="verifyForm.codeEmail"
                        autocomplete="off"
                        placeholder="验证码"
                      >
                        <vh-button :disabled="true" size="mini" slot="append">发送验证码</vh-button>
                      </vh-input>
                    </vh-col>
                  </vh-row>
                </vh-form-item>
                <vh-form-item class="provicy-item" v-if="provicy" :prop="provicy.id + ''">
                  <!-- 隐私声明 -->
                  <template>
                    <div class="previewPrivacy-content">
                      <vh-checkbox
                        class="checkbox"
                        v-model="form[provicy.id]"
                        v-if="provicy.is_must"
                      ></vh-checkbox>
                      <pre class="pre-html" v-html="provicyText"></pre>
                    </div>
                  </template>
                </vh-form-item>
                <div class="btnBox">
                  <vh-button
                    :disabled="true"
                    :class="[baseInfo.theme_color, 'length152']"
                    round
                    type="primary"
                  >
                    提交
                  </vh-button>
                </div>
                <!-- 隐私协议合规 -->
                <privacy-select scene="signForm" v-if="!provicy" compType="2"></privacy-select>
              </vh-form>
            </template>
          </article>
        </div>
      </vue-scroll>
      <i v-if="!isEntryForm" class="closeBtn" @click="closePreview">
        <i class="iconfont-v3 saasicon_close"></i>
      </i>
    </div>
  </div>
</template>

<script>
  import Env from '@/api/env';
  import PrivacySelect from '../../../Login/components/privacy-select.vue';
  import { parseQueryString } from '@/utils/utils.js';
  import SubjectLives from '../../../SpecialModule/components/subjectLives.vue';
  export default {
    components: {
      PrivacySelect,
      SubjectLives
    },
    props: {
      currentFormData: {
        type: Object,
        default: () => {
          return null;
        }
      },
      // 是否是双语双流
      isBilingual: {
        type: Boolean,
        default: false
      }
    },
    created() {
      this.tabs = 1;
      this.loadData();
      // 禁止底层滚动
      document.querySelector('html').classList.add('noScroll');
    },
    destroyed() {
      // 禁止底层滚动
      document.querySelector('html').classList.remove('noScroll');
    },
    watch: {
      coverUrl(newVal) {
        if (newVal) {
          let obj = parseQueryString(newVal);
          if (obj.mode) {
            this.coverImageMode = Number(obj.mode) || 2;
          }
        }
      },
      province(newVal, oldVal) {
        if (newVal != oldVal) {
          this.city = '';
          this.county = '';
        }
      },
      city(newVal, oldVal) {
        if (newVal != oldVal) {
          this.county = '';
        }
      },
      isPhoneValidate: {
        immediate: true,
        handler(newVal) {
          // 云盾实例
          if (newVal) {
            this.$nextTick(() => {
              this.callCaptcha('#setCaptcha');
              this.callCaptcha('#setCaptcha1');
            });
          }
        }
      },
      isEmailValidate: {
        immediate: true,
        handler(newVal) {
          // 云盾实例
          if (newVal) {
            this.$nextTick(() => {
              this.callCaptcha('#setCaptchaEmail');
              this.callCaptcha('#setCaptchaEmail1');
            });
          }
        }
      },
      list: {
        deep: true,
        handler(newList) {
          let form = {};
          let hasValidFlag = [];
          // let isPhoneValidate = false
          let isMustPhone = false;
          // let isEmailValidate = false
          let isMustEmail = false;
          newList &&
            newList.length &&
            newList.forEach(item => {
              form[item.id] = '';
              if (item.type === 3) {
                form[item.id] = [];
                item.items.forEach(opt => {
                  if (opt.type === 1) {
                    form[`${item.id}${opt.id}`] = '';
                  }
                });
              } else if (item.type === 0 && item.default_type === 2 && this.currentPhone) {
                // 手机号
                form[item.id] = this.currentPhone;
              } else if (item.type === 2) {
                // 单选/多选
                item.items.forEach(opt => {
                  if (opt.type === 1) {
                    form[`${item.id}${opt.id}`] = '';
                  }
                });
              }
              // 生成验证规则
              if (item.type === 0 && item.default_type === 2) {
                // isPhoneValidate = item.options && JSON.parse(item.options).open_verify == 1
                isMustPhone = item.is_must;
                hasValidFlag[0] = 1;
              } else if (item.type === 0 && item.default_type === 3) {
                // isEmailValidate = item.options && JSON.parse(item.options).open_verify == 1
                isMustEmail = item.is_must;
                hasValidFlag[1] = 1;
              }
            });
          this.form = {
            imgCode: '',
            code: '',
            imgCodeEmail: '',
            codeEmail: '',
            ...form
          };
          if (hasValidFlag[0] == 1 && hasValidFlag[1] == 1) {
            //邮箱手机号同时存在
            this.validType = isMustPhone ? 'phone' : 'email';
          } else if (hasValidFlag[0] == 1 && hasValidFlag[1] != 1) {
            //手机存在，邮箱不存在
            this.validType = 'phone';
          } else if (hasValidFlag[0] != 1 && hasValidFlag[1] == 1) {
            //手机不存在，邮箱存在
            this.validType = 'email';
          } else {
            this.validType = 'phone';
          }
          if (!isMustPhone && !isMustEmail) {
            this.validType = 'phone';
          }

          this.$nextTick(() => {
            if (hasValidFlag[0] == 1) {
              this.callCaptcha('#setCaptcha');
              this.callCaptcha('#setCaptcha1');
            }
            if (hasValidFlag[1] == 1) {
              this.callCaptcha('#setCaptchaEmail');
              this.callCaptcha('#setCaptchaEmail1');
            }
          });
        }
      },
      'currentFormData.language_type': {
        deep: true,
        handler(newVal, oldVal) {
          if (newVal !== oldVal) {
            // 这里需要等中英文表单数据切换完成，不能改成this.$nextTick
            setTimeout(() => {
              this.loadData();
            }, 0);
          }
        }
      }
    },
    computed: {
      cityList() {
        return this.cities[this.province];
      },
      countyList() {
        return this.counties[this.city];
      },
      coverUrl() {
        if (this.baseInfo.form_cover.startsWith('http')) {
          return this.baseInfo.form_cover;
        } else {
          return `${this.Env.staticLinkVo.uploadBaseUrl}${this.baseInfo.form_cover}`;
        }
      }
    },
    data() {
      return {
        languageType: 1,
        ops: {
          //滚动条配置
          bar: {
            background: 'rgba(0,0,0,0.3)'
          }
        },
        Env: Env,
        isEntryForm: this.$route.path.startsWith('/entryform'), // 是否是独立表单
        colorIndex: 'red',
        tabs: 1,
        province: '',
        city: '',
        county: '',
        provinces: [],
        cities: {},
        counties: {},
        list: [],
        isEmailValidate: false,
        isPhoneValidate: false,
        currentPhone: '',
        errorMsgShow: false,
        showCaptcha: false, // 专门用于 校验登录次数 接口返回 需要显示图形验证码时使用
        captchakey: 'b7982ef659d64141b7120a6af27e19a0', // 云盾key
        mobileKey: '', // 云盾值
        captcha1: null, // 云盾实例
        captcha2: null, // 云盾实例
        captchaEmail1: null, // 云盾实例
        captchaEmail2: null, // 云盾实例
        time: 60,
        provicy: false,
        provicyText: '',
        placeholderL: ['', ''],
        placeholderList: {
          1: '请输入姓名',
          2: '请输入手机号',
          3: '请输入邮箱',
          5: {
            province: '请选择省份',
            city: '请选择市',
            county: '请选择区/县'
          },
          6: '请输入验证码'
        },
        rules: {},
        form: {},
        answer: {},
        baseInfo: {
          cover: 1,
          open_link: 0,
          theme_color: 'red',
          tab_verify_title: '验证',
          tab_form_title: '用户报名',
          intro: ''
        },
        verifyForm: {
          phone: '',
          imgCode: '',
          email: '',
          imgCodeEmail: ''
        },
        verifyErrorMsgShow: false,
        verifyTime: 60,
        colNum: 8,
        regionalId: '',
        isVerifyCodeErr: false,
        overflowStatus: 0, // 文本溢出的状态，0 未溢出；1 溢出未展开；2溢出展开
        signUpPageType:
          window.location.href.indexOf('/live/signup/') != -1 ||
          window.location.href.indexOf('/lives/entryform') != -1
            ? 'webinar'
            : window.location.href.indexOf('/special/viewer/') != -1 ||
              window.location.href.indexOf('/special/entryform') != -1
            ? 'subject'
            : '',
        webinarOrSubjectId:
          window.location.href.indexOf('/live/signup/') != -1
            ? this.$route.params.str
            : window.location.href.indexOf('/special/viewer/') != -1 ||
              window.location.href.indexOf('/lives/entryform') != -1 ||
              window.location.href.indexOf('/special/entryform') != -1
            ? this.$route.params.id || this.$route.params.str
            : '',
        coverImageMode: 2,
        checkboxs: [],
        validType: 'phone',
        phoneItem: {
          options: {
            placeholder: '请输入手机号'
          }
        }, //手机验证信息
        emailItem: {
          options: {
            placeholder: '请输入邮箱'
          }
        } //邮箱验证信息
      };
    },
    mounted() {
      // new DevicePixelRatio('#signFormBox');
    },
    methods: {
      getPlaceholder(question) {
        let opt = question.options;
        if (question.options && typeof question.options === 'string') {
          opt = JSON.parse(question.options);
        }
        let pl = opt?.placeholder || this.placeholderList[question.options];
        if (!pl) {
          if (question.system_type == 0 && question.type === 1) {
            return this.languageType === 2 ? 'Enter your answer' : '请输入答案';
          }
          return this.languageType === 2 ? 'Please enter' : '请输入';
        }
        return pl;
      },
      handleLanguageTypeChange() {
        this.$emit('previewLanguageChange', this.languageType);
      },
      // 设置接口入参，是活动维度 还是 专题维度
      setParamsIdByRoute(params) {
        if (this.signUpPageType === 'webinar') {
          params.webinar_id = this.webinarOrSubjectId;
        } else if (this.signUpPageType === 'subject') {
          params.subject_id = this.webinarOrSubjectId;
        }
        return params;
      },
      handleAutoCloseSelect() {
        this.$refs.autoCloseRefFlag &&
          this.$refs.autoCloseRefFlag.forEach(item => {
            item.blur();
          });
      },
      handleUnfold(val) {
        this.overflowStatus = val;
      },
      regionalChange(type) {
        if (type == 'county') {
          this.form[this.regionalId] = this.county;
        }
        this.$refs['form'].validateField('' + this.regionalId, res => {
          console.log(res);
        });
      },
      closePreview() {
        this.$emit('closeSignUp');
      },
      loadData() {
        // 获取表单基本信息
        this.baseInfo = this.currentFormData;
        this.languageType = this.baseInfo.language_type;
        console.log('loadData this.currentFormData:', this.currentFormData);
        this.$nextTick(() => {
          this.calculateText();
        });
        this.getQuestionList();
      },
      calculateText() {
        const txtDom = this.$refs.intro;
        if (!txtDom) return false;
        const twoHeight = 40;
        const curHeight = txtDom.offsetHeight;
        if (curHeight > twoHeight) {
          this.overflowStatus = 1;
        }
      },

      /**
       * 初始化网易易盾图片验证码
       */
      callCaptcha(id) {
        let captcha = '';
        if (id == '#setCaptcha') {
          captcha = id === '#setCaptcha' ? 'captcha1' : 'captcha2';
        }
        if (id == '#setCaptchaEmail') {
          captcha = id === '#setCaptchaEmail' ? 'captchaEmail1' : 'captchaEmail2';
        }
        if (!document.querySelector(id)) return;
        const that = this;
        // eslint-disable-next-line
        initNECaptcha({
          captchaId: that.captchakey,
          element: id,
          mode: 'float',
          lang: this.languageType == 2 ? 'en' : 'zh-CN',
          onReady(instance) {
            console.log('instance', instance);
          },
          onVerify(err, data) {
            if (data) {
              that.mobileKey = data.validate;
              that.showCaptcha = true;
              console.log('data>>>', data);
              that.errorMsgShow = '';
            } else {
              that[captcha] = '';
              console.log('errr>>>', err);
              that.errorMsgShow = true;
            }
          },
          onload(instance) {
            console.log('onload', instance);
            that[captcha] = instance;
          }
        });
      },
      // 验证 or  提交答案后，逻辑跳转处理
      renderEndToPage() {
        if (this.signUpPageType === 'webinar') {
          this.getWebinarStatus();
        }
      },
      // 获取地域列表
      getAreaList() {
        this.$fetch('getAreaListJson').then(res => {
          // console.warn(res, '加载地址');
          this.provinces = res.provinces;
          this.cities = res.cities;
          this.counties = res.counties;
        });
      },
      // 获取表单题目列表
      getQuestionList() {
        function compare(property) {
          return function (a, b) {
            var value1 = a[property];
            var value2 = b[property];
            return value1 - value2;
          };
        }
        // 按照 order_num 从小到大排序
        const list = this.currentFormData.ques_list.sort(compare('order_num'));
        // 手机号验证开启状态
        const phoneItem = list.find(item => item.type == 0 && item.default_type == 2);
        if (phoneItem) {
          this.phoneItem = JSON.parse(JSON.stringify(phoneItem));
          this.phoneItem.options = phoneItem.options && JSON.parse(phoneItem.options);
        }
        //邮箱
        const emailItem = list.find(item => item.type == 0 && item.default_type == 3);
        if (emailItem) {
          this.emailItem = JSON.parse(JSON.stringify(emailItem));
          this.emailItem.options = emailItem.options && JSON.parse(emailItem.options);
        }
        this.isPhoneValidate =
          phoneItem && phoneItem.options && JSON.parse(phoneItem.options).open_verify == 1;
        this.isEmailValidate =
          emailItem && emailItem.options && JSON.parse(emailItem.options).open_verify == 1;
        // 默认填写手机号
        this.list = list;
        // 地域 options 格式化处理
        this.list.some(item => {
          if (item.type == 5) {
            item.options =
              item.options && typeof item.options === 'string'
                ? JSON.parse(item.options)
                : item.options;
            item.colNum = 8;
            item.options.show_district == 0 && (item.colNum = 12);
            item.options.show_city == 0 && (item.colNum = 24);
            this.colNum = item.colNum;
            this.regionalId = item.id;
            return true;
          }
        });
        // this.list.map(item =>)
        // 隐私声明格式处理
        const lastQuestion = this.list[this.list.length - 1];
        console.log(lastQuestion);
        if (lastQuestion?.subject === '隐私声明') {
          this.provicy = lastQuestion;
          this.provicy && this.privacyFormatter();
        } else {
          this.provicy = false;
        }
        list.some(item => item.type === 5) && this.getAreaList();
        // list.some(item => item.type === 7) &&  this.$refs.subjectLives.initComp();
        // }).catch(err => {
        //   console.log(err);
        // });
      },
      privacyFormatter() {
        let parseOpts = [];
        if (typeof this.provicy.options == 'string') {
          parseOpts = JSON.parse(this.provicy.options);
        } else {
          parseOpts = this.provicy.options;
        }
        let parseOptsTextList = parseOpts.filter((item, index) => {
          let options = JSON.parse(item.options);
          parseOpts[index].index = index;
          parseOpts[index].optionsOBJ = options;
          return options.privacy_info;
        });
        const parseOptsFir = parseOpts[0] && parseOpts[0].optionsOBJ;

        let text = parseOptsFir.content;
        let matchPrivacy1 = parseOptsFir.color_text.trim()
          ? text.match(parseOptsFir.color_text)
          : null;
        if (matchPrivacy1 && parseOptsFir.url) {
          let joinstr = `<a href="${parseOptsFir.url}" target="_blank">${matchPrivacy1[0]}</a>`;
          text = text.split(matchPrivacy1[0]).join(joinstr);
        }

        for (let i = 0; i < parseOptsTextList.length; i++) {
          let parseOpts = parseOptsTextList[i].optionsOBJ;
          let privacy_info =
            parseOpts && parseOpts.privacy_info.trim() ? text.match(parseOpts.privacy_info) : null;
          if (privacy_info && parseOpts.privacy_link) {
            let joinstr = `<a href="${parseOptsFir.url}" target="_blank">${privacy_info[0]}</a>`;
            text = text.split(privacy_info[0]).join(joinstr);
          }
        }
        this.provicyText = text;
      },
      getCheckedIds(val, obj) {
        if (val == 0) {
          this.form[obj] = 0;
        } else {
          this.form[obj] = val.join(',');
        }
      }
    }
  };
</script>

<style lang="less" scoped>
  @import '~@/common/css/other/variables.less';

  @red: #fb3a32;
  @redBg: #ffebeb;
  @blue: #3562fa;
  @blueBg: #ebefff;
  @purple: #8d57a4;
  @purpleBg: #f5bdea;
  .signFormBox {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1002;
    display: flex;
    justify-content: center;
    align-items: center;
    &.signFormBoxHid {
      position: static;
      background-color: #fff;
      width: auto;
      height: auto;
    }
    .signWrap {
      overflow-y: auto;
      height: 85%;
      border-radius: 4px;
      background: #fff;
      position: relative;
      z-index: 101;

      &.signWrapHid {
        height: auto;
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.15);
      }
      .entryFormBox {
        width: 720px;
        background: #fff;
        padding-bottom: 87px;
      }
      header {
        width: 100%;
        height: 120px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .signWrap__header {
          &-1 {
            object-fit: fill;
          }
          &-2 {
            object-fit: cover;
            object-position: left top;
          }
          &-3 {
            object-fit: contain;
            object-position: center;
          }
        }
      }

      .pageTitle {
        font-size: 22px;
        color: #1a1a1a;
        margin: 40px 0 22px;
        text-align: center;

        line-height: 33px;
      }
      .pageIntro {
        // width: 658px;
        // margin: 20px auto 0;
        color: #666666;
        font-size: 14px;
        line-height: 20px;
        word-break: break-all;
        font-weight: 400;
        position: relative;
        pre {
          word-wrap: break-word;
          word-break: break-word;
          white-space: pre-wrap;
        }
        &.pageIntroEllipsis {
          word-break: break-all;
          text-overflow: ellipsis;
          display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
          -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
          -webkit-line-clamp: 2; /** 显示的行数 **/
          overflow: hidden; /** 隐藏超出的内容 **/
        }
        .textTail {
          position: absolute;
          bottom: 0px;
          right: 4px;
          cursor: pointer;
          background-color: #fff;
          color: #3562fa;
          .isEllipsis {
            color: #666666;
          }
        }
      }
      article {
        padding: 0 75px;
      }
      .closeBtn {
        width: 24px;
        height: 24px;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 28px;
        color: #fff;
        position: absolute;
        right: 16px;
        top: 16px;
        font-style: normal;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        .saasicon_close {
          font-size: 14px;
        }
      }
      .tabsBox {
        width: 100%;
        margin: 43px auto 20px;
        display: flex;
        justify-content: center;
      }
      .tabs {
        width: 484px;
        overflow: hidden;
        font-size: 16px;
        > div {
          width: 50%;
          float: left;
          border-radius: 4px;
          border: 1px solid #e6e6e6;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 40px;
          text-align: center;
          transition: all 0.2s linear;
          cursor: pointer;
          &:nth-child(1) {
            border-right: 0px none;
            border-radius: 4px 0px 0px 4px;
          }
          &:nth-child(2) {
            border-left: 0px none;
            border-radius: 0px 4px 4px 0px;
          }
          &.active {
            border: 1px solid @red;
            background: @redBg;
            color: @red;
          }
        }
        &.red {
          .active {
            border: 1px solid @red;
            background: @redBg;
            color: @red;
          }
        }
        &.blue {
          .active {
            border: 1px solid @blue;
            background: @blueBg;
            color: @blue;
          }
        }
        &.purple {
          .active {
            border: 1px solid @purple;
            background: @purpleBg;
            color: @purple;
          }
        }
      }

      .validPhoneOrEmail {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        margin-top: 12px;
        .item {
          font-size: 16px;
          cursor: pointer;
          &.active {
            color: #fb3a32;
          }
        }
        .split {
          width: 1px;
          height: 16px;
          background-color: #d9d9d9;
          margin: 0 10px;
        }
      }

      .noFull {
        width: calc(100% - 30px);
      }
    }
    .provicy-item {
      margin-bottom: 24px !important;
    }
    .previewPrivacy-content {
      display: flex;
      align-items: flex-start;
      .checkbox {
        padding-right: 8px;
        margin-top: -10px;
      }
      .pre-html {
        flex: 1;
        font-size: 14px;
        line-height: 22px;
        color: #595959;
        ::v-deep a {
          color: #3562fa;
          &:active,
          &:visited,
          &:hover {
            color: #3562fa;
          }
        }
      }
    }
    .entryForm {
      ::v-deep .vh-form-item.is-required:not(.is-no-asterisk) > .vh-form-item__label:before {
        content: '*';
        color: #fb3a32;
        margin-right: 4px;
        position: absolute;
        left: -10px;
      }

      ::v-deep .vh-form-item__label {
        position: relative;
        float: none;
        display: block;
        line-height: 22px;
        text-align: left;
        font-size: 14px;
        padding-bottom: 10px;
      }

      ::v-deep .vh-radio-group,
      ::v-deep .vh-checkbox-group {
        width: 100%;
        & > div {
          padding: 10px 0 10px;
        }
      }
      ::v-deep .vh-checkbox-group .vh-checkbox,
      ::v-deep .vh-radio-group .vh-radio {
        display: flex;
        align-items: flex-start;
        white-space: normal;
      }

      .btnBox {
        display: flex;
        justify-content: center;
        padding-top: 12px;
      }

      .verifyCodeBox {
        // 云盾样式重置,注释部分为设计稿样式，暂时不删除，有备无患
        .captcha {
          //   /deep/ .yidun .yidun_control {
          //     border-radius: 4px!important;
          //     border-color: #ccc;
          //     background: #fff;
          //     overflow: hidden;
          //     .yidun_slide_indicator {
          //       border-radius: 4px!important;
          //     }
          ::v-deep .yidun_tips {
            color: #999999;
            line-height: 38px !important;
            .yidun_tips__text {
              vertical-align: initial;
            }
          }
          ::v-deep .yidun_slider {
            .yidun_slider__icon {
              background-image: url(../images/icon-slide1.png) !important;
              background-size: 28px 20px;
              background-position: center;
              margin-top: -5px;
            }
            &:hover {
              // background-color: #FB3A32;
              .yidun_slider__icon {
                background-image: url(../images/icon-slide.png) !important;
              }
            }
          }
          //     &.yidun_control--moving {
          //       background-color: #E2E2E2;
          //       border-color: #FB3A32;
          //       .yidun_slide_indicator {
          //         border-color: #FB3A32;
          //         background-color: #E2E2E2;
          //       }
          //     }

          // }
          ::v-deep .yidun--success {
            // .yidun_control--moving {
            //   background-color: #F0F1FE!important;
            //   .yidun_slide_indicator {
            //     background-color: #F0F1FE!important;
            //   }
            // }
            .yidun_control {
              .yidun_slider__icon {
                background-image: url(../images/icon-succeed.png) !important;
              }
              .yidun_slider {
                .yidun_slider__icon {
                  background-image: url(../images/icon-succeed.png);
                  background-size: 28px 20px;
                  background-position: center;
                }
                &:hover {
                  // background-color: #FB3A32;
                  .yidun_slider__icon {
                    background-image: url(../images/icon-succeed.png);
                    background-size: 28px 20px;
                    background-position: center;
                  }
                }
              }
            }
          }
        }
        // .yidun.yidun--light.yidun--success.yidun--jigsaw {
        //   .yidun_control .yidun_slider {
        //     background-color: #3562FA;
        //   }
        //   .yidun_slide_indicator {
        //     border-color: #3562FA;
        //     background-color: #E2E2E2;
        //   }
        // }
        .yidun.yidun--light {
          .yidun_feedback {
            background-position: 0px -240px;
            height: 30px;
          }
          .yidun_refresh {
            background-position: 0px -339px;
          }
        }
      }

      .vh-select {
        width: 100%;
      }
    }
    .entryForm .blue {
      background: @blue!important;
      border-color: @blue!important;
      &:hover {
        background: @blue!important;
        border-color: @blue!important;
      }
    }
    .entryForm .red {
      background: @red!important;
      border-color: @red!important;
      &:hover {
        background: @red!important;
        border-color: @red!important;
      }
    }
    .entryForm .purple {
      background: @purple!important;
      border-color: @purple!important;
      &:hover {
        background: @purple!important;
        border-color: @purple!important;
      }
    }

    pre {
      white-space: pre-wrap;
      word-wrap: break-word;
    }
  }
</style>
<style lang="less">
  html.noScroll {
    overflow: hidden;
    height: 100%;
  }
  .signFormBox .vh-input-group__append {
    .vh-button.is-disabled,
    .vh-button.is-disabled:focus,
    .vh-button.is-disabled:hover {
      color: inherit;
      cursor: not-allowed;
      background-image: none;
      background: #fafafa;
      border-color: transparent;
    }
  }

  .question-of-select {
    .vh-select-dropdown {
      left: 0 !important;
      position: absolute !important;
      top: 35px !important;

      .vh-select-dropdown__item {
        white-space: normal;
        line-height: 1.5;
        margin-bottom: 10px;
        height: auto;
        word-break: break-word;
      }
    }
  }
</style>
