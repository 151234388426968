let filedJson = {
  radio: {
    label: '单选题',
    maxlength: 130,
    required: true,
    reporType: 100160,
    bottomBtn: ['addBtn', 'addOther', 'delete', 'move', 'requireSwtich'],
    type: 'radio',
    value: '',
    nodes: [
      {
        props: {},
        value: '',
        children: [
          {
            item_id: 0,
            other: false,
            question_id: 0,
            type: 0,
            value: '选项',
            placeholder: '选项'
          },
          {
            item_id: 0,
            other: false,
            question_id: 0,
            type: 0,
            value: '选项',
            placeholder: '选项'
          }
        ]
      }
    ],
    reqType: 2,
    default_type: ''
  },
  checkBox: {
    label: '多选题',
    required: true,
    bottomBtn: ['addBtn', 'addOther', 'delete', 'move', 'requireSwtich'],
    type: 'checkBox',
    reporType: 100166,
    value: '',
    maxlength: 130,
    nodes: [
      {
        props: {},
        value: [],
        children: [
          { item_id: 0, question_id: 0, type: 0, value: '选项', placeholder: '选项' },
          { item_id: 0, question_id: 0, type: 0, value: '选项', placeholder: '选项' }
        ]
      }
    ],
    reqType: 3,
    default_type: ''
  },
  select: {
    label: '下拉题',
    required: true,
    bottomBtn: ['addBtn', 'delete', 'move', 'requireSwtich'],
    type: 'select',
    reporType: 100176,
    value: '',
    nodes: [
      {
        props: {
          maxlength: 100,
          'show-word-limit': true,
          placeholder: '选项',
          class: ['selectInput']
        },
        canRemove: true,
        value: '',
        item_id: 0,
        question_id: 0,
        type: 0,
        value: '选项'
      },
      {
        props: {
          maxlength: 100,
          'show-word-limit': true,
          placeholder: '选项',
          class: ['selectInput']
        },
        canRemove: true,
        value: '',
        item_id: 0,
        question_id: 0,
        type: 0,
        value: '选项'
      }
    ],
    reqType: 4,
    default_type: '',
    placeholder: '选项'
  },
  input: {
    label: '问答题',
    required: true,
    reporType: 100172,
    bottomBtn: ['delete', 'move', 'requireSwtich'],
    type: 'input',
    value: '',
    nodes: [
      {
        props: {
          placeholder: '请输入答案'
        },
        value: '请输入答案'
      }
    ],
    reqType: 1,
    default_type: '',
    disabledEdit: false
  },
  inputs: {
    label: '小结',
    required: false,
    bottomBtn: ['delete', 'move', 'requireSwtich'],
    type: 'input',
    value: '',
    nodes: [
      {
        props: {
          placeholder: '请输入总结'
        },
        value: ''
      }
    ]
  },
  privacy: {
    reporType: 100181
  },
  webinars: {
    label: '请选择活动报名',
    maxlength: 100,
    required: true,
    reporType: 0,
    bottomBtn: ['move'],
    type: 'webinars',
    placeholder: '请输入提示',
    value: '',
    nodes: [
      {
        props: {
          placeholder: '请选择活动报名'
        },
        value: ''
      }
    ],
    reqType: 7,
    default_type: '',
    disabledEdit: true
  }
};
const types = ['', '', 'radio', 'checkBox', 'select', '', ''];

export const basePreset = {
  //中文
  zh: {
    title: {
      placeholder: '请输入表单标题'
    },
    intro: {
      placeholder: '请输入表单简介'
    }
  },
  // 英文
  en: {
    title: {
      placeholder: '请输入英文标题'
    },
    intro: {
      placeholder: '请输入英文简介'
    }
  }
};

const filedPreset = {
  //中文
  zh: {
    name: {
      placeholder: '请输入姓名',
      value: '请输入姓名',
      label: '名字'
    },
    phone: {
      placeholder: '请输入手机号',
      value: '请输入手机号',
      label: '手机'
    },
    birthday: {
      placeholder: '请输入生日',
      label: '生日'
    },
    gender: {
      label: '性别',
      children: ['男', '女']
    },
    email: {
      placeholder: '请输入邮箱',
      value: '请输入邮箱',
      label: '邮箱'
    },
    company: {
      placeholder: '请输入公司名称',
      value: '请输入公司名称',
      label: '公司'
    },
    regional: {
      label: '地域',
      nodeOptions: ['省/自治区/直辖市', '市', '区/县']
    },
    privacy: {
      content: {
        placeholder: '我们根据《隐私声明》保护您填写的所有信息',
        value: '我们根据《隐私声明》保护您填写的所有信息'
      },
      color_text: {
        placeholder: '请输入第1行中包含的文字才能实现跳转效果',
        value: '《隐私声明》'
      }
    }
  },
  // 英文
  en: {
    name: {
      placeholder: '请输入英文提示语',
      value: 'Enter your name',
      label: 'Name'
    },
    phone: {
      placeholder: '请输入英文提示语',
      value: 'Enter your phone number',
      label: 'Phone'
    },
    birthday: {
      placeholder: '请输入英文提示语',
      label: 'Birthday'
    },
    gender: {
      label: 'Gender',
      children: ['Male', 'Female']
    },
    email: {
      placeholder: '请输入英文提示语',
      value: 'Enter your email',
      label: 'Email'
    },
    company: {
      placeholder: '请输入英文提示语',
      value: 'The company you work for',
      label: 'Company'
    },
    regional: {
      label: 'Region',
      nodeOptions: ['Province', 'City', 'District']
    },
    privacy: {
      content: {
        placeholder:
          'We protect all the information you provide according to our 《Privacy Statement》',
        value: 'We protect all the information you provide according to our 《Privacy Statement》'
      },
      color_text: {
        placeholder: '请输入第1行中包含的文字才能实现跳转效果',
        value: '《Privacy Statement》'
      }
    }
  }
};

export function getfiledJson({ name, type, defaultQaInfo, lang = 'zh' }) {
  let json = '';
  let i = 0;
  let t = 0;
  let nodes = {};
  if (name) {
    switch (name) {
      case 'name':
        json = JSON.parse(JSON.stringify(filedJson['input']));
        json.nodes[0].props.placeholder = filedPreset[lang][name].placeholder;
        json.nodes[0].value = filedPreset[lang][name].value;
        json = Object.assign(json, {
          label: filedPreset[lang][name].label,
          required: true,
          bottomBtn: ['move'],
          reqType: 0,
          default_type: 1,
          subject: '名字',
          disabledEdit: false
        });

        break;
      case 'phone':
        json = JSON.parse(JSON.stringify(filedJson['input']));
        json.nodes[0].props.placeholder = filedPreset[lang][name].placeholder;
        json.nodes[0].value = filedPreset[lang][name].value;
        json = Object.assign(json, {
          label: filedPreset[lang][name].label,
          required: true,
          bottomBtn: ['delete', 'move', 'requireSwtich', 'phoneValid'],
          reqType: 0,
          default_type: 2,
          subject: '手机',
          phoneValide: true,
          abroadPhoneValide: false,
          disabledEdit: false
        });

        break;
      case 'birthday':
        json = JSON.parse(JSON.stringify(filedJson['input']));
        json.nodes[0].props.placeholder = filedPreset[lang][name].placeholder;
        json = Object.assign(json, {
          label: filedPreset[lang][name].label,
          required: true,
          bottomBtn: ['move', 'phoneValid'],
          disabledEdit: false
        });

        break;
      case 'gender':
        json = JSON.parse(JSON.stringify(filedJson['radio']));
        json.nodes[0].children[0].value = filedPreset[lang][name].children[0];
        json.nodes[0].children[1].value = filedPreset[lang][name].children[1];
        json.maxlength = 100;
        json = Object.assign(json, {
          label: filedPreset[lang][name].label,
          reporType: 100140,
          required: true,
          bottomBtn: ['delete', 'move', 'requireSwtich'],
          reqType: 0,
          default_type: 4,
          subject: '性别'
        });

        break;

      case 'email':
        json = JSON.parse(JSON.stringify(filedJson['input']));
        json.nodes[0].props.placeholder = filedPreset[lang][name].placeholder;
        json.nodes[0].value = filedPreset[lang][name].value;
        json = Object.assign(json, {
          label: filedPreset[lang][name].label,
          required: true,
          reqType: 0,
          reporType: 100144,
          bottomBtn: ['delete', 'move', 'requireSwtich', 'emailValid'],
          default_type: 3,
          subject: '邮箱',
          emailValide: false,
          disabledEdit: false
        });

        break;

      // 公司
      case 'company':
        json = JSON.parse(JSON.stringify(filedJson['input']));
        json.nodes[0].props.placeholder = filedPreset[lang][name].placeholder;
        json.nodes[0].value = filedPreset[lang][name].value;
        json = Object.assign(json, {
          label: filedPreset[lang][name].label,
          required: true,
          reporType: 100152,
          bottomBtn: ['delete', 'move', 'requireSwtich'],
          default_type: 9,
          options: {
            type: 7
          },
          disabledEdit: false
        });

        break;

      // 行业
      case 'industry':
        t = type || defaultQaInfo.field_type || 4;
        i = types[t];
        json = JSON.parse(JSON.stringify(filedJson[i])); //select

        nodes = {};
        if (t == 4) {
          //下拉框
          nodes = defaultQaInfo.field_options.map(item => {
            return {
              props: {
                placeholder: '',
                class: ['selectInput']
              },
              canRemove: true,
              value:
                lang === 'en'
                  ? item.option_names_en?.option_name
                  : item.option_names_zh?.option_name,
              item_id: 0,
              question_id: 0,
              type: 0,
              general_option_key: item.option_key
            };
          });
        } else {
          //单选多选
          let cl = defaultQaInfo.field_options.map(item => {
            return {
              props: {
                placeholder: '',
                class: []
              },
              canRemove: true,
              value:
                lang === 'en'
                  ? item.option_names_en?.option_name
                  : item.option_names_zh?.option_name,
              item_id: 0,
              question_id: 0,
              type: 0,
              general_option_key: item.option_key
            };
          });
          nodes = [
            {
              props: {},
              value: [],
              children: cl
            }
          ];
        }

        json = Object.assign(json, {
          label:
            lang === 'en'
              ? defaultQaInfo.field_names_en?.field_name || 'Industry'
              : defaultQaInfo.field_names_zh?.field_name || '行业',
          required: true,
          general_field_key: defaultQaInfo.field_key, //通用字段KEY
          system_type: 11,
          disabledEdit: true,
          bottomBtn: ['delete', 'move', 'requireSwtich', 'choseOption'],
          default_type: 11,
          nodes: nodes,
          options: {
            question_subject:
              lang === 'en'
                ? defaultQaInfo.field_names_en?.field_name || 'Industry'
                : defaultQaInfo.field_names_zh?.field_name || '行业',
            general_field_options: defaultQaInfo.field_options
          }
        });

        break;

      // 地域
      case 'regional':
        json = JSON.parse(JSON.stringify(filedJson['select']));
        json = Object.assign(json, {
          label: filedPreset[lang][name].label,
          required: true,
          bottomBtn: ['delete', 'move', 'requireSwtich'],
          nodes: filedPreset[lang][name].nodeOptions.map((item, index) => {
            return {
              props: {
                disabled: true,
                placeholder: item,
                class: ['selectInput', 'regionalInput']
              },
              value: '',
              index
            };
          }),
          reqType: 5,
          reporType: 100148,
          default_type: 5,
          options: {
            show_country: 1,
            show_province: 1,
            show_city: 1,
            show_district: 1
          },
          disabledEdit: true
        });

        break;

      // 职务
      case 'duty':
        t = type || defaultQaInfo.field_type || 3;
        i = types[t];
        json = JSON.parse(JSON.stringify(filedJson[i])); //checkBox

        nodes = {};
        if (t == 4) {
          //下拉框
          nodes = defaultQaInfo.field_options.map(item => {
            return {
              props: {
                placeholder: '',
                class: ['selectInput']
              },
              canRemove: true,
              value:
                lang === 'en'
                  ? item.option_names_en?.option_name
                  : item.option_names_zh?.option_name,
              item_id: 0,
              question_id: 0,
              type: 0,
              general_option_key: item.option_key
            };
          });
        } else {
          //单选多选
          let cl = defaultQaInfo.field_options.map(item => {
            return {
              props: {
                placeholder: '',
                class: []
              },
              canRemove: true,
              value:
                lang === 'en'
                  ? item.option_names_en?.option_name
                  : item.option_names_zh?.option_name,
              item_id: 0,
              question_id: 0,
              type: 0,
              general_option_key: item.option_key
            };
          });
          nodes = [
            {
              props: {},
              value: [],
              children: cl
            }
          ];
        }

        json = Object.assign(json, {
          label:
            lang === 'en'
              ? defaultQaInfo.field_names_en?.field_name || 'Post'
              : defaultQaInfo.field_names_zh?.field_name || '职务',
          required: true,
          general_field_key: defaultQaInfo.field_key, //通用字段KEY
          reporType: 100156,
          system_type: 12,
          disabledEdit: true,
          bottomBtn: ['delete', 'move', 'requireSwtich', 'choseOption'],
          default_type: 12,
          nodes: nodes,
          options: {
            question_subject:
              lang === 'en'
                ? defaultQaInfo.field_names_en?.field_name || 'Post'
                : defaultQaInfo.field_names_zh?.field_name || '职务',
            general_field_options: defaultQaInfo.field_options
          }
        });

        break;
      //职务（旧）
      case 'duty_old':
        json = JSON.parse(JSON.stringify(filedJson['select']));

        json = Object.assign(json, {
          label: '职务',
          required: true,
          reporType: 100156,
          system_type: 8,
          default_type: 8,
          nodes: [
            '首席执行官/总经理',
            '首席信息官/IT经理',
            '市场总监/经理',
            '销售总监/经理',
            '技术总监/经理',
            '工程技术人员',
            '其他'
          ].map(item => {
            return {
              props: {
                placeholder: '选项',
                class: ['selectInput']
              },
              canRemove: true,
              value: item,
              item_id: 0,
              question_id: 0,
              type: 0
            };
          }),
          options: {
            item_list: [
              '首席执行官/总经理',
              '首席信息官/IT经理',
              '市场总监/经理',
              '销售总监/经理',
              '技术总监/经理',
              '工程技术人员',
              '其他'
            ],
            question_subject: '职务',
            immutable: true
          }
        });

        break;
      // 职位
      case 'position':
        t = type || defaultQaInfo.field_type || 2;
        i = types[t];
        json = JSON.parse(JSON.stringify(filedJson[i])); //radio

        nodes = {};
        if (t == 4) {
          //下拉框
          nodes = defaultQaInfo.field_options.map(item => {
            return {
              props: {
                placeholder: '',
                class: ['selectInput']
              },
              canRemove: true,
              value:
                lang === 'en'
                  ? item.option_names_en?.option_name
                  : item.option_names_zh?.option_name,
              item_id: 0,
              question_id: 0,
              type: 0,
              general_option_key: item.option_key
            };
          });
        } else {
          //单选多选
          let cl = defaultQaInfo.field_options.map(item => {
            return {
              props: {
                placeholder: '',
                class: []
              },
              canRemove: true,
              value:
                lang === 'en'
                  ? item.option_names_en?.option_name
                  : item.option_names_zh?.option_name,
              item_id: 0,
              question_id: 0,
              type: 0,
              general_option_key: item.option_key
            };
          });
          nodes = [
            {
              props: {},
              value: [],
              children: cl
            }
          ];
        }

        json = Object.assign(json, {
          label:
            lang === 'en'
              ? defaultQaInfo.field_names_en?.field_name || 'Position'
              : defaultQaInfo.field_names_zh?.field_name || '职位',
          required: true,
          general_field_key: defaultQaInfo.field_key, //通用字段KEY
          system_type: 10,
          disabledEdit: true,
          bottomBtn: ['delete', 'move', 'requireSwtich', 'choseOption'],
          default_type: 10,
          nodes: nodes,
          options: {
            question_subject:
              lang === 'en'
                ? defaultQaInfo.field_names_en?.field_name || 'Position'
                : defaultQaInfo.field_names_zh?.field_name || '职位',
            general_field_options: defaultQaInfo.field_options
          }
        });

        break;

      case 'educational':
        json = JSON.parse(JSON.stringify(filedJson['select']));

        json = Object.assign(json, {
          label: '教育水平',
          required: true,
          // "bottomBtn": [,"delete", "move", "requireSwtich"],
          nodes: ['博士', '研究生', '本科', '专科', '其他'].map(item => {
            return {
              props: {
                disabled: item != '其他',
                placeholder: '选项',
                class: ['selectInput']
              },
              canRemove: true,
              value: item
            };
          })
        });

        break;

      case 'privacy':
        json = JSON.parse(JSON.stringify(filedJson['input']));
        json = Object.assign(json, {
          label: '隐私声明',
          required: true,
          bottomBtn: ['delete'],
          privacy: true,
          reporType: 100181,
          nodes: [
            {
              key: 'content',
              props: {
                class: ['radioInput'],
                placeholder: filedPreset[lang][name]['content'].placeholder,
                maxlength: 1000,
                minRows: 4,
                type: 'textarea'
              },
              value: filedPreset[lang][name]['content'].value
            },
            {
              key: 'color_text',
              props: {
                class: ['radioInput'],
                placeholder: filedPreset[lang][name]['color_text'].placeholder,
                maxlength: 1000,
                type: 'text'
              },
              value: filedPreset[lang][name]['color_text'].value,
              privacyAdd: true,
              canRemove: false
            },
            {
              key: 'url',
              props: {
                placeholder: '请输入以http://或https://开头的链接',
                maxlength: 200,
                minRows: 2,
                type: 'textarea'
              },
              value: ''
            }
          ],
          reqType: 6,
          default_type: 6,
          disabledEdit: false
        });
        break;

      default:
        break;
    }
    json.name = name;
  } else {
    json = JSON.parse(JSON.stringify(filedJson[type]));
    json.labelEditable = true;
    switch (type) {
      // 单选题/多选题
      case 'radio':
      case 'checkBox':
        {
          json.placeholder = lang === 'en' ? '请输入英文选项' : '选项';
          for (let ele of json.nodes[0].children) {
            ele.placeholder = lang === 'en' ? '请输入英文选项' : '选项';
            ele.value = lang === 'en' ? 'Options' : '选项';
          }
        }
        break;
      // 下拉题
      case 'select':
        {
          json.placeholder = lang === 'en' ? '请输入英文选项' : '选项';
          for (let ele of json.nodes) {
            ele.props.placeholder = lang === 'en' ? '请输入英文选项' : '选项';
            ele.value = lang === 'en' ? 'Options' : '选项';
          }
        }
        break;
      // 问答题
      case 'input':
        {
          json.label = lang === 'en' ? 'Q&A' : '问答题';
          json.placeholder = lang === 'en' ? 'Enter your answer' : '请输入答案';
          for (let ele of json.nodes) {
            ele.props.placeholder = lang === 'en' ? 'Enter your answer' : '请输入答案';
            ele.value = lang === 'en' ? '' : '请输入答案';
          }
        }
        break;
      case 'webinars':
        json.placeholder = lang === 'en' ? '请输入英文提示' : '请输入提示';
        json.label = lang === 'en' ? 'Please select' : '请选择活动报名';
        json.nodes[0].props.placeholder = lang === 'en' ? '请输入英文提示语' : '请选择活动报名';
        break;
    }
  }
  return json;
}
