<template>
  <div class="user-manage">
    <user-registration
      :isBilingual="isBilingual"
      :languageType="languageType"
      :webinarOrSubjectId="webinarOrSubjectId"
      :signUpPageType="signUpPageType"
    ></user-registration>
  </div>
</template>

<script>
  import UserRegistration from '../../PlatformModule/UserRegistration/index';
  export default {
    components: {
      UserRegistration
    },
    props: {
      // 活动ID 或者 专题ID，跟signUpPageType字段组合使用
      webinarOrSubjectId: {
        type: [Number, String],
        default: 0
      },
      // 报名表单类型：webinar--活动；subject--专题
      signUpPageType: {
        type: [Number, String],
        default: ''
      },
      // 是否双语专题
      isBilingual: {
        type: Boolean,
        default: false
      },
      // 1 中文，2：英文, 默认为 1
      languageType: {
        type: [Number, String],
        default: 1
      }
    },
    data() {
      return {};
    },
    created() {},
    methods: {}
  };
</script>

<style lang="less" scoped>
  .user-manage {
    padding: 24px 24px 0 24px;
  }
</style>
