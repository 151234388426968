<template>
  <div class="fieldSetBox">
    <!-- v-model="list" -->
    <!-- 表单名称、表单简介与表单头图为固定字段 -->
    <section class="viewItem">
      <p class="label">表单名称</p>
      <vh-input
        maxlength="50"
        show-word-limit
        :placeholder="currentBasePreset.title.placeholder"
        v-model="title"
        @change="baseInfoChange('title')"
      ></vh-input>
      <div class="disable_wrap" v-show="!signUpSwtich"></div>
    </section>
    <section class="viewItem">
      <p class="label">表单简介</p>
      <vh-input
        :maxlength="500"
        autocomplete="off"
        v-model="intro"
        show-word-limit
        :placeholder="currentBasePreset.intro.placeholder"
        type="textarea"
        :autosize="{ minRows: 5 }"
        resize="none"
        @change="baseInfoChange('intro')"
        class="intro-input"
      ></vh-input>
      <div class="disable_wrap" v-show="!signUpSwtich"></div>
    </section>
    <section class="viewItem">
      <div class="show-des banner">
        头图
        <vh-switch
          v-model="baseInfo.show_head_img"
          size="mini"
          class="banner-switch"
          :active-value="1"
          :inactive-value="0"
        >
        </vh-switch>
        设置表单页顶部图样式
      </div>
<!--      <p class="label">表单头图</p>-->
      <upload
        ref="form_headpic_uplader"
        v-model="imageUrl"
        :domain_url="imageParamsUrl"
        :saveData="saveImageData"
        :on-success="headpicUploadSuccess"
        :on-change="handleUploadChange"
        :restPic="true"
        :widthImg="'auto'"
        :heightImg="120"
        :isShowDel="imageParamsUrl!==defaultHeader"
        @delete="deleteBanner"
        :auto-upload="false"
      >
        <div slot="tip">
          <p>建议尺寸：750*120px，小于2M</p>
          <p>支持jpg、gif、png、bmp</p>
        </div>
      </upload>
      <span class="header-img-tip">
        建议尺寸：750*120 px，小于2MB（支持格式jpg、png、gif、bmp）
      </span>
      <div class="disable_wrap" v-show="!signUpSwtich"></div>
    </section>
    <!-- 表单名称、表单简介与表单头图为固定字段 -->
    <draggable
      class="list-group"
      tag="ul"
      handle=".moveBtn"
      v-bind="dragOptions"
      v-model="renderQuestion"
      @change="sortChange"
      @start="drag = true"
      @end="drag = false"
      :move="onMove"
    >
      <!-- 加上v-model即可排序后实时更新数据 -->
      <transition-group type="transition" :name="!drag ? 'flip-list' : null">
        <li
          :ref="item.question_id"
          :class="[
            'viewItem',
            signUpSwtich ? 'viewItemHover' : '',
            item.privacy ? 'privacyItem' : '',
            curQaInfo.question_id == item.question_id ? 'active' : '',
            item.reqType === 4?'filed-item-drag-group':''
          ]"
          v-for="(item, index) in renderQuestion"
          :key="item.question_id"
          @click="choseQa(item)"
        >
          <p class="label">
            {{ index | numFormmat }}.
            <!-- {{item.required ? '（必填）' : ''}} -->
            <template
              v-if="
                item.reqType == 6 ||
                item.system_type == 10 ||
                item.system_type == 11 ||
                item.system_type == 12
              "
            >
              {{ item.label }}
            </template>
            <template v-else>
              <div class="titleArea" :class="{ subjectError: item.subjectError }">
                <vh-input
                  :maxlength="item.maxlength ? item.maxlength : 100"
                  autocomplete="off"
                  show-word-limit
                  :placeholder="languageType == 2 ? '请输入英文题目' : '请输入题目'"
                  v-model="item.label"
                  class="radioInput titleInput"
                  @change="subjectChange(item)"
                ></vh-input>
                <div class="txtError" v-show="item.subjectError">{{ item.subjectError }}</div>
              </div>
            </template>
          </p>
          <draggable
            tag="ul"
            handle=".sort-item2"
            v-model="item.nodes"
          >
            <div v-for="(node, nodeIndex) in item.nodes">
              <!-- 输入框类型 || 设置表单时下拉框类型 -->
              {{ node.other ? '其他' : '' }}
              <div
                v-if="item.type === 'input' || item.type === 'select'"
                style="position: relative"
                :class="[item.reqType === 4?'filed-item-drag-group__item':'']"
              >
                <div class="sort-item2" v-if="item.reqType === 4">
                  <img width="8" src="https://cnstatic01.e.vhall.com/upload/interacts/screen-imgs/202404/4e/00/4e0048314a03b356b433a19a7e41584a9065.png" alt="" style="cursor: move;">
                </div>
                <vh-input
                  :disabled="item.disabledEdit"
                  v-model="node.value"
                  v-bind="node.props"
                  show-word-limit
                  autocomplete="off"
                  :type="node.props && node.props.type ? node.props.type : 'text'"
                  :autosize="node.props && node.props.minRows ? { minRows: node.props.minRows } : ''"
                  :maxlength="node.props && node.props.maxlength ? node.props.maxlength : '100'"
                  :key="`${index}-${nodeIndex}`"
                  :class="[item.reqType === 4?'filed-item-drag-group__item-input':'']"
                  class="radioInput"
                  @change="
                    selectOptChange(item, node, item.type === 'select', item.privacy, nodeIndex)
                  "
                >
                  <i
                    class="vh-icon-remove-outline removeIcon"
                    slot="suffix"
                    v-if="!!node.canRemove && !item.disabledEdit"
                    @click="deleteOptions(item, nodeIndex, item.privacy ? 'privacy' : 'select')"
                    v-show="
                      (item.privacy && item.nodes.length > 3) ||
                      (!item.privacy && item.nodes.length !== 2)
                    "
                  ></i>
                  <i
                    class="vh-icon-circle-plus-outline removeIcon"
                    slot="suffix"
                    v-if="!!node.privacyAdd && item.nodes.length < 11"
                    @click="privacyAdd(item.nodes, item)"
                  ></i>
                </vh-input>
                <!-- 区域级别下拉开启状态控制 -->
                <vh-switch
                  v-model="regionalLevel[node.index]"
                  @change="regionalLevelChange(node.index, item)"
                  v-if="item.reqType == 5 && node.index != 0"
                  class="regionalBtn"
                  size="mini"
                  :key="`statusctrl${index}-${nodeIndex}`"
                ></vh-switch>
              </div>
              <!-- 单选类型 -->
              <vh-radio-group
                v-model="item.value"
                v-bind="node.props"
                v-else-if="item.type === 'radio' && node.children.length !== 0"
                :key="`${index}-${nodeIndex}`"
                class="filed-item-drag-group"
              >
                <draggable
                  tag="ul"
                  handle=".sort-item"
                  v-model="node.children"
                >
                  <vh-radio
                    disabled
                    :name="item.id"
                    v-for="(radioItem, raionIndex) in node.children"
                    :key="`${index}-${nodeIndex}-${raionIndex}`"
                    :label="radioItem.item_id"
                    class="filed-item-drag-group__item"
                  >
                    <div class="sort-item">
                      <img width="8" src="https://cnstatic01.e.vhall.com/upload/interacts/screen-imgs/202404/4e/00/4e0048314a03b356b433a19a7e41584a9065.png" alt="" style="cursor: move;">
                    </div>
                    <vh-input
                      :disabled="(item.reqType == 0 && item.default_type == 4) || item.disabledEdit"
                      @change="chooseOptChange(item, radioItem)"
                      :maxlength="130"
                      autocomplete="off"
                      show-word-limit
                      :placeholder="radioItem.placeholder"
                      v-model="radioItem.value"
                      class="radioInput"
                      :class="{ radioGender: item.reqType == 0 && item.default_type == 4 }"
                    >
                      <i
                        v-show="
                          item.nodes[0].children.length >= 2 && !item.disabledEdit && item.reqType == 2
                        "
                        class="vh-icon-remove-outline removeIcon"
                        slot="suffix"
                        @click="deleteOptions(item, raionIndex, 'option')"
                      ></i>
                    </vh-input>
                    <br />
                    <vh-input
                      class="other-input"
                      placeholder="观众输入区"
                      disabled
                      v-if="radioItem.other"
                      :maxlength="130"
                      autocomplete="off"
                      show-word-limit
                      :key="`${index}-${nodeIndex}disabled`"
                    ></vh-input>
                  </vh-radio>
                </draggable>
              </vh-radio-group>
              <!-- 复选框类型 -->
              <vh-checkbox-group
                v-model="item.value"
                v-bind="node.props"
                v-if="item.type === 'checkBox'"
                :key="`'vh-checkbox-group-'${index}-${nodeIndex}`"
                class="filed-item-drag-group"
              >
                <draggable
                  tag="ul"
                  handle=".sort-item"
                  v-model="node.children"
                >
                  <vh-checkbox
                    disabled
                    v-for="(radioItem, raionIndex) in node.children"
                    :key="`${index}-${nodeIndex}-${raionIndex}`"
                    :label="radioItem.item_id"
                    :name="item.id"
                    :class="{ 'other-checkbox': radioItem.other }"
                    class="filed-item-drag-group__item"
                  >
                    <div class="sort-item">
                      <img width="8" src="https://cnstatic01.e.vhall.com/upload/interacts/screen-imgs/202404/4e/00/4e0048314a03b356b433a19a7e41584a9065.png" alt="" style="cursor: move;">
                    </div>
                    <vh-input
                      :maxlength="130"
                      autocomplete="off"
                      show-word-limit
                      :placeholder="radioItem.placeholder"
                      :disabled="item.disabledEdit"
                      v-model="radioItem.value"
                      class="radioInput"
                      @change="chooseOptChange(item, radioItem)"
                    >
                      <i
                        v-show="item.nodes[0].children.length !== 2 && !item.disabledEdit"
                        class="vh-icon-remove-outline removeIcon"
                        slot="suffix"
                        @click="deleteOptions(item, raionIndex, 'option')"
                      ></i>
                    </vh-input>
                    <br />
                    <vh-input
                      class="other-input"
                      placeholder="观众输入区"
                      disabled
                      v-if="radioItem.other"
                      :maxlength="130"
                      show-word-limit
                      :key="`${index}-${nodeIndex}disabled`"
                    ></vh-input>
                  </vh-checkbox>
                </draggable>
              </vh-checkbox-group>
            </div>
          </draggable>

          <!---->
          <div class="privacy-switch" v-if="item.privacy">
            <vh-switch
              @change="requiredSwitchChange(item)"
              class="swtich"
              size="mini"
              v-model="item.required"
              active-color="#FB3A32"
              inactive-color="#CECECE"
              active-text="隐私声明显示勾选框，需要用户勾选后再提交"
            ></vh-switch>
          </div>
          <div class="previewPrivacy" v-if="item.privacy">
            <p>预览效果</p>
            <div class="previewPrivacy-content">
              <vh-checkbox class="checkbox" v-if="item.privacy && item.required"></vh-checkbox>
              <pre class="pre-html" v-html="privacyFormatter(item.nodes)"></pre>
            </div>
          </div>
          <div class="webinars" v-if="item.type === 'webinars'">
            <SubjectLives
              ref="subjectLives"
              :isBilingual="isBilingual"
              :languageType="languageType"
              :selectable="false"
              :headerCellStyle="{ background: '#f7f7f7', color: '#262626', height: '40px' }"
              :rowStyle="{ background: '#f7f7f7' }"
            ></SubjectLives>
          </div>
          <!-- 底部按钮 -->
          <div class="bottomBtn" v-if="!!item.bottomBtn">
            <div
              :class="[
                'addBtn',
                (item.nodes[0] && item.nodes[0].children && item.nodes[0].children.length >= 30) ||
                item.nodes.length >= 30
                  ? 'isoverflow'
                  : ''
              ]"
            >
              <vh-button
                type="text"
                v-if="item.bottomBtn.includes('addBtn')"
                v-preventReClick
                @click="addOption(item)"
              >
                <i class="vh-icon-plus"></i>
                添加选项
              </vh-button>
              <vh-button
                type="text"
                v-if="item.bottomBtn.includes('choseOption')"
                v-preventReClick
                @click="choseOption(item)"
              >
                选择选项
              </vh-button>
              <template v-if="item.bottomBtn.includes('addOther')">
                <span class="line"></span>
                <vh-button v-preventReClick type="text" @click="addOption(item, 'other')">
                  <i class="vh-icon-plus"></i>
                  添加其他
                </vh-button>
              </template>
            </div>
            <div class="controlBtnBox">
              <vh-tooltip class="item" effect="dark" content="删除" placement="top" v-tooltipMove>
                <i
                  class="iconfont-v3 saasicon-trash"
                  v-if="item.bottomBtn.includes('delete')"
                  @click="deleteQuestion(questionArr, index, item)"
                ></i>
              </vh-tooltip>
              <vh-tooltip class="item" effect="dark" content="移动" placement="top" v-tooltipMove>
                <i
                  class="iconfont-v3 saasicon_move moveBtn"
                  v-if="item.bottomBtn.includes('move')"
                ></i>
              </vh-tooltip>
            </div>
          </div>
          <!-- 底部按钮 -->
          <div class="disable_wrap" v-show="!signUpSwtich"></div>
        </li>
      </transition-group>
    </draggable>
    <section class="viewItem sureBtn" v-if="signUpPageType === 'webinar'">
      <vh-button
        :disabled="!signUpSwtich"
        round
        type="primary"
        v-preventReClick
        @click="sureQuestionnaire"
        class="length152"
      >
        保存
      </vh-button>
    </section>
    <!-- 裁剪图片弹窗 -->
    <cropper
      ref="formCropper"
      :currentMode="currentMode"
      @cropComplete="cropComplete"
      @resetUpload="resetChooseFile"
      :ratio="750 / 120"
    ></cropper>
    <vh-dialog
      title="选择选项"
      :visible.sync="dialogOptionVisible"
      width="800px"
      @close="closeOptionDialog"
      custom-class="choseOptionDialog"
    >
      <vh-checkbox
        :indeterminate="isIndeterminate"
        v-model="checkAllOption"
        @change="handleCheckAllChange"
      >
        全选
      </vh-checkbox>
      <div style="margin: 15px 0"></div>
      <vh-checkbox-group v-model="checkedOptions" @change="handleCheckedOptionsChange">
        <draggable
          class="list-group"
          tag="div"
          handle=".moveBtn"
          v-bind="dragOptions"
          v-model="originOptions"
          @change="sortOptionChange"
          @start="dragOption = true"
          @end="dragOption = false"
          :move="onMoveOption"
        >
          <!-- 加上v-model即可排序后实时更新数据 -->
          <transition-group type="transition" :name="!dragOption ? 'flip-list' : null">
            <div v-for="op in originOptions" :key="op.option_key">
              <vh-checkbox :label="op.option_key" :key="op.option_key">
                <div class="inputGroup">
                  <div class="inputs">
                    <vh-input readonly :value="op.option_name_zh" class="radioInput"></vh-input>
                  </div>
                  <div class="inputs">
                    <vh-input readonly :value="op.option_name_en" class="radioInput"></vh-input>
                  </div>
                  <span
                    class="iconfont-v3 saasicon_move moveBtn"
                    @click="
                      e => {
                        e.preventDefault();
                      }
                    "
                  ></span>
                </div>
              </vh-checkbox>
            </div>
          </transition-group>
        </draggable>
      </vh-checkbox-group>
      <div slot="footer" class="dialog-footer">
        <vh-button type="info" @click="closeOptionDialog" round plain size="medium">取消</vh-button>
        <vh-button
          type="primary"
          @click="handlerOptionConfirm"
          round
          size="medium"
          v-preventReClick
        >
          确定
        </vh-button>
      </div>
    </vh-dialog>
  </div>
</template>

<script>
  import draggable from 'vuedraggable';
  import upload from '@/components/Upload/main';
  import Env from '@/api/env';
  import defaultHeader from './images/formHeader.png';
  import { buildOssUploadProcessData, parseQueryString, buildUrlByMode } from '@/utils/utils';
  import cropper from '@/components/Cropper/index';
  import SubjectLives from '../../SpecialModule/components/subjectLives.vue';
  import { basePreset } from './util';

  export default {
    components: {
      draggable,
      upload,
      cropper,
      SubjectLives
    },
    inject: {
      subject_verify_live: { value: 'subject_verify_live', default: false }
    },
    props: {
      questionArr: {
        type: Array,
        default: () => []
      },
      baseInfo: {
        type: Object
      },
      // 是否双语专题
      isBilingual: {
        type: Boolean,
        default: false
      },
      // 1 中文，2：英文, 默认为 1
      languageType: {
        type: [Number, String],
        default: 1
      },
      signUpSwtich: {
        type: Boolean
      },
      regionalOptions: {
        type: Object
      }
    },
    data() {
      return {
        title: '', // 基本信息——表单名称
        intro: '', // 基本信息——表单简介
        drag: false,
        dragOption: false,
        radio: 3,
        imageUrl: '',
        // blurryDegree: 0,
        // lightDegree: 10,
        defaultHeader: defaultHeader,
        renderQuestion: [],

        regionalLevel: {
          1: true, // 市
          2: true // 区/县
        },
        signUpPageType:
          window.location.href.indexOf('/live/signup/') !== -1 ||
          window.location.href.indexOf('/lives/entryform') !== -1
            ? 'webinar'
            : window.location.href.indexOf('/special/viewer/') !== -1 ||
              window.location.href.indexOf('/special/entryform') !== -1
            ? 'subject'
            : '',
        webinarOrSubjectId:
          window.location.href.indexOf('/live/signup/') !== -1
            ? this.$route.params.str
            : window.location.href.indexOf('/special/viewer/') !== -1 ||
              window.location.href.indexOf('/lives/entryform') !== -1 ||
              window.location.href.indexOf('/special/entryform') !== -1
            ? this.$route.params.id || this.$route.params.str
            : '',
        curQaInfo: {
          question_id: ''
        },
        isIndeterminate: false,
        originOptions: [], //总的option
        checkedOptions: [], //当前的option
        checkAllOption: false,
        dialogOptionVisible: false,
        upload_process_data: '', //上传组件的附加数据
        currentMode: 1, //当前的正在使用的裁切模式
      };
    },
    computed: {
      dragOptions() {
        return {
          animation: 200,
          group: 'description',
          disabled: false,
          ghostClass: 'ghost'
        };
      },
      //保存表单的完整图片地址
      imageParamsUrl() {
        if (this.imageUrl) {
          return buildUrlByMode(this.imageUrl, this.currentMode);
        } else {
          return this.defaultHeader;
        }
      },
      subject_verify_live_child() {
        return (this.subject_verify_live && this.subject_verify_live()) || false;
      },
      /**
       * 聚合表单的提交数据
       */
      handledData() {
        let baseInfo = {
          form_title: this.title,
          form_introduce: this.intro,
          form_cover: this.imageUrl ? this.imageParamsUrl : ''
        };
        return { ...baseInfo, ques_list: this.renderQuestion };
      },
      currentBasePreset() {
        return this.languageType == 2 ? basePreset['en'] : basePreset['zh'];
      },
      // 保存的上传图片的附加数据
      saveImageData() {
        let imageData = {
          path: 'sys/img_url',
          type: 'image'
        };
        if (this.upload_process_data) {
          imageData.process = this.upload_process_data;
        }
        return imageData;
      }
    },
    watch: {
      // 监听头图
      imageUrl: {
        handler(newVal, oldVal) {
          if (newVal != oldVal) {
            this.questionEdit('base');
          }
        }
      },
      regionalOptions: {
        handler(newVal) {
          this.regionalLevel = { ...newVal };
        },
        deep: true,
        immediate: true
      },
      questionArr: {
        handler(newVal) {
          // console.log('更新questionArr:', newVal);
          for (let i = 0; i < newVal.length; i++) {
            const element = newVal[i];
            if (!element.label) {
              element.subjectError = '请输入内容';
            } else {
              element.subjectError = '';
            }
          }
          this.renderQuestion = newVal;
          this.questionEdit();
        },
        deep: true
      },
      baseInfo: {
        handler(newVal) {
          console.log('--fieldSet baseInfo有变化:', newVal);
          this.title = newVal.title;
          this.intro = newVal.intro;
          this.handlerImage(newVal);
        },
        deep: true
      },
      subject_verify_live_child(val) {
        if (val) {
          // this.$refs.subjectLives.initComp();
        } else {
          for (let i = this.renderQuestion.length - 1; i > -1; i--) {
            if (this.renderQuestion[i].type === 'webinars') {
              this.renderQuestion.splice(i, 1);
            }
          }
        }
      },
      signUpSwtich(val) {
        if (!val) {
          this.curQaInfo = {
            question_id: ''
          };
        }
      },
      languageType(val) {
        console.log('fieldSet languageType:', val);
      }
    },
    filters: {
      numFormmat(val) {
        return val < 9 ? `0${val + 1}` : val + 1;
      }
    },
    methods: {
      initCurQaInfo() {
        this.curQaInfo = {
          question_id: ''
        };
      },
      // 设置接口入参，是活动维度 还是 专题维度
      setParamsIdByRoute(params) {
        if (this.signUpPageType === 'webinar') {
          params.webinar_id = this.webinarOrSubjectId;
        } else if (this.signUpPageType === 'subject') {
          params.subject_id = this.webinarOrSubjectId;
        }
        return params;
      },
      // 区域级别下拉菜单开启状态控制
      regionalLevelChange(level, question) {
        if (level == 1 && !this.regionalLevel[1]) {
          this.regionalLevel[2] = false;
        }
        if (level == 2 && this.regionalLevel[2]) {
          this.regionalLevel[1] = true;
        }
        question.options.show_city = this.regionalLevel[1] ? 1 : 0;
        question.options.show_district = this.regionalLevel[2] ? 1 : 0;
        this.subjectChange(question);
      },
      onMove({ relatedContext, draggedContext }) {
        const relatedElement = relatedContext.element;
        const draggedElement = draggedContext.element;
        if (relatedElement.reqType === 0) {
          if (relatedElement.default_type === 1 || relatedElement.default_type === 2) {
            return false;
          }
        } else if (relatedElement.reqType === 6) {
          return false;
        } else {
          return true;
        }
      },
      onMoveOption() {
        return true;
      },
      // 处理图片
      handlerImage(val) {
        if (!val.cover) {
          // this.defaultHeader = defaultHeader;
          return;
        }
        if (val.cover.indexOf('https://') === -1) {
          val.cover = `${Env.staticLinkVo.uploadBaseUrl}${val.cover}`;
        }
        this.imageUrl = val.cover;
        let obj = parseQueryString(val.cover);
        if (obj.mode) {
          this.currentMode = obj.mode;
        }
      },
      // 保存表单
      sureQuestionnaire() {
        if (this.signUpPageType === 'subject') {
          return;
        }
        let userId = this.$parent.userId;
        console.log(this.renderQuestion);
        this.renderQuestion
          .filter(item => item.name !== 'name')
          .map(item => {
            this.$vhall_paas_port({
              k: item.reporType,
              data: {
                business_uid: userId,
                user_id: '',
                webinar_id: this.webinarOrSubjectId,
                refer: '',
                s: '',
                report_extra: {},
                ref_url: '',
                req_url: ''
              }
            });
            if (item.reqType !== 6) {
              this.$vhall_paas_port({
                k: item.required ? item.reporType + 1 : item.reporType + 2,
                data: {
                  business_uid: userId,
                  user_id: '',
                  webinar_id: this.webinarOrSubjectId,
                  refer: '',
                  s: '',
                  report_extra: {},
                  ref_url: '',
                  req_url: ''
                }
              });
            }
            if (item.reqType === 4) {
              this.$vhall_paas_port({
                k: item.reporType + 4,
                data: {
                  business_uid: userId,
                  user_id: '',
                  webinar_id: this.webinarOrSubjectId,
                  refer: '',
                  s: '',
                  report_extra: { num: item.nodes.length },
                  ref_url: '',
                  req_url: ''
                }
              });
            } else if (item.reqType === 2 || item.reqType === 3) {
              let other = 0;
              let total = item.nodes[0].children.length;
              if (total > 0) {
                other = item.nodes[0].children.filter(items => items.other).length;
              }
              this.$vhall_paas_port({
                k: item.reporType + 4,
                data: {
                  business_uid: userId,
                  user_id: '',
                  webinar_id: this.webinarOrSubjectId,
                  refer: '',
                  s: '',
                  report_extra: { num: total - other },
                  ref_url: '',
                  req_url: ''
                }
              });
              this.$vhall_paas_port({
                k: item.reporType + 5,
                data: {
                  business_uid: userId,
                  user_id: '',
                  webinar_id: this.webinarOrSubjectId,
                  refer: '',
                  s: '',
                  report_extra: { num: other },
                  ref_url: '',
                  req_url: ''
                }
              });
            }
          });
        this.$emit('doSave');
      },
      //选择 通用字段的 选项
      choseOption(info) {
        this.curQaInfo = info;
        //使用通用字段 行业，职务职位
        this.originOptions = [];
        this.checkedOptions = [];
        let general_field_options = info.options.general_field_options;
        general_field_options.map(e => {
          let name_zh = '';
          let name_en = '';
          e.option_names.map(e2 => {
            if (e2.lang_type == '0') {
              name_zh = e2.option_name;
            }
            if (e2.lang_type == '1') {
              name_en = e2.option_name;
            }
          });

          if (info.reqType == 4) {
            //下拉
            info.nodes.map(e2 => {
              if (e.option_key == e2.general_option_key) {
                this.checkedOptions.push(e.option_key);
              }
            });
          } else {
            info.nodes[0].children.map(e2 => {
              if (e.option_key == e2.general_option_key) {
                this.checkedOptions.push(e.option_key);
              }
            });
          }

          this.originOptions.push({
            option_key: e.option_key,
            option_name_zh: name_zh,
            option_name_en: name_en
          });
        });

        this.handleCheckedOptionsChange(this.checkedOptions);

        this.$nextTick(() => {
          this.dialogOptionVisible = true;
        });
      },
      // 添加一个题目选项
      addOption(data, other) {
        if (
          (data.nodes[0].children && data.nodes[0].children.length >= 30) ||
          data.nodes.length >= 30
        ) {
          this.$vhMessage({
            message: `最多可添加30个选项`,
            showClose: true,
            type: 'error',
            customClass: 'zdy-info-box'
          });
          return false;
        }
        // let itemType = other ? 1 : 0;
        let options =
          data.type !== 'input' && data.type !== 'select' ? data.nodes[0].children : data.nodes;

        let colneChild = JSON.parse(JSON.stringify(options[options.length - 1]));
        if (data.type === 'input') {
          if (other) {
            // itemType = 1;
            if (colneChild.props.class) {
              !colneChild.props.class.includes('noFull') && colneChild.props.class.push('noFull');
            } else {
              colneChild.props.class = ['noFull'];
            }
          } else {
            colneChild.props.class &&
              (colneChild.props.class = colneChild.props.class.filter(item => item != 'noFull'));
          }
        }
        colneChild.props && (colneChild.props.disabled = false);
        colneChild.other = !!other;
        colneChild.value = '';
        if(colneChild.other){ // 其他
          colneChild.type = 1
        }else{
          colneChild.type = 0
        }
        options.push({
          ...colneChild,
          value: '选项',
          item_id: 'node_id_' + new Date().getTime() + Math.ceil(Math.random() * 1000)
        });
        this.questionEdit();
      },
      // 题目属性改变（通用）
      questionEdit(triggerItem) {
        this.$emit('getQaInfo', this.handledData, triggerItem);
      },
      // 选项编辑（通用）
      optionEdit(options) {
        this.questionEdit();
      },
      // 题目 title 改变
      subjectChange(question) {
        this.questionEdit('base');
      },
      // 下拉题目选项 subject 改变
      selectOptChange(question, node, isSelect, isPrivacy, nodeIndex) {
        // 如果是更改的隐私声明的跳转链接需要验证链接格式
        if (node.key === 'url') {
          const reg = /http[s]{0,1}:\/\/([\w.]+\/?)\S*/;
          if (!reg.test(node.value)) {
            this.$vhMessage({
              message: `请输入正确格式的跳转链接！`,
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
            node.value = '';
          }
        }
        // 只有 select 才可以更改
        if (isSelect) {
          const options = {
            item_id: node.item_id,
            subject: node.value,
            question_id: question.question_id
          };
          this.optionEdit(options);
        }
        // 如果改变的是隐私声明
        if (isPrivacy) {
          // 如果更改默认添加的隐私声明，调用更新题目接口
          console.log('node.privacy_id----', node.privacy_id);
          if (!node.privacy_id) {
            this.questionEdit();
          } else {
            // 如果更改自定义添加的隐私声明，调用更新隐私协议的接口
            let options = {
              ...this.setParamsIdByRoute({}), // 活动ID 或者 专题ID
              privacy_id: node.privacy_id,
              content: question.nodes[0].value,
              color_text: node.key === 'url' ? question.nodes[nodeIndex - 1].value : node.value,
              url: node.key === 'url' ? node.value : question.nodes[nodeIndex + 1].value
            };
            console.log('nodeIndex-----node.key', question.nodes, options);
            this.questionEdit();
          }
        }
      },
      // 单选\多选题目选项 subject 改变
      chooseOptChange(item, radioItem) {
        const options = {
          item_id: radioItem.item_id,
          subject: radioItem.value,
          question_id: item.question_id
        };
        this.optionEdit(options);
      },
      // 删除一个题目
      deleteQuestion(arr, index, item) {
        console.log(arr, index, item);
        this.$vhConfirm('删除后已收集信息会被清空，确认删除？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          customClass: 'zdy-message-box',
          lockScroll: false,
          roundButton: true,

          cancelButtonClass: 'zdy-confirm-cancel'
        }).then(() => {
          this.delQa(arr[index].question_id, index, item.reporType);
        });
      },
      // 删除一个题目选项
      deleteOptions(item, index, type) {
        // 删除一个隐私协议选项
        if (type === 'privacy') {
          // 隐私协议删除两个元素 且不能有数量限制
          let reg = new RegExp(`(和${item.nodes[3].value})`);
          item.nodes[0].value = item.nodes[0].value.replace(reg, '');

          this.questionEdit();

          item.nodes.splice(index, 2);
          return;
        } else if (type === 'select') {
          if (item.nodes.length === 2) {
            return this.$vhMessage({
              message: `请至少保留两个选项`,
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
          }
          item.nodes.splice(index, 1);
          this.questionEdit();
        } else {
          // 普通选项删除
          if (item.reqType != 2 && item.nodes[0].children.length == 2) {
            return this.$vhMessage({
              message: `请至少保留两个选项`,
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
          }

          item.nodes[0].children.splice(index, 1);
          this.questionEdit();
        }
      },
      // 添加一个隐私协议
      privacyAdd(nodes, question) {
        if (nodes[0].value.length + 8 > 1000) {
          return this.$vhMessage({
            message: '添加隐私协议会超出预览字数，请删减后再添加',
            showClose: true,
            type: 'error',
            customClass: 'zdy-info-box'
          });
        }
        let cloneNode = JSON.parse(JSON.stringify(nodes[1]));
        let cloneNode2 = JSON.parse(JSON.stringify(nodes[2]));
        cloneNode.value = '';
        cloneNode.privacyAdd = false;
        cloneNode.canRemove = true;
        cloneNode2.value = '';
        nodes.push(cloneNode);
        nodes.push(cloneNode2);
        this.questionEdit();
      },
      privacyFormatter(item) {
        let itemList = JSON.parse(JSON.stringify(item));
        let text = item[0].value;
        for (let i = 1; i < itemList.length; i++) {
          if (itemList[i].key === 'color_text') {
            let matchPrivacy = itemList[i].value.trim()
              ? text.match(itemList[i].value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'))
              : null;
            if (matchPrivacy) {
              let url = itemList[i + 1].value;
              if (url) {
                let joinstr = `<a href="${url}" target="_blank">${matchPrivacy[0]}</a>`;
                text = text.split(matchPrivacy[0]).join(joinstr);
              }
            } else {
              item[i].value = '';
            }
          }
        }
        return text;
      },
      /**
       * 文件上传成功回调
       *
       * @param   {[type]}  res   [res description]
       * @param   {[type]}  file  [file description]
       *
       * @return  {[type]}        [return description]
       */
      headpicUploadSuccess(res, file) {
        if (res.data) {
          this.imageUrl = res.data.domain_url;
        }
      },
      /**
       * 文件变更时的回调
       *
       * @param   {[type]}  file      [file description]
       * @param   {[type]}  fileList  [fileList description]
       *
       * @return  {[type]}            [return description]
       */
      handleUploadChange(file, fileList) {
        if (file.status === 'ready') {
          this.$checkUploadType(file.raw, this, 1, 2, () => {
            let reader = new FileReader();
            reader.readAsDataURL(file.raw);
            reader.onload = e => {
              this.$refs.formCropper.showModel(e.target.result);
            };
          });
        }
      },
      // 清除上传的图片和默认头图
      deleteBanner() {
        this.imageUrl = '';
        this.questionEdit('base');
      },
      cropComplete(cropperData, url, mode) {
        this.currentMode = mode;
        if (cropperData) {
          this.upload_process_data = buildOssUploadProcessData({
            cropperData: cropperData
            // blurryDegree: blurryDegree,
            // lightDegree: lightDegree
          });
        } else {
          this.upload_process_data = '';
        }

        this.$nextTick(() => {
          this.$refs.form_headpic_uplader.upload();
        });
      },
      resetChooseFile() {
        this.$refs.form_headpic_uplader.resetChooseFile();
      },
      // 题目顺序修改
      sortChange(val, arr) {
        console.log('sortChange-->', this.renderQuestion);
        // 同步父组件数据
        this.$emit('update:questionArr', this.renderQuestion);
        let question_ids = this.renderQuestion.reduce((acc, curr) => {
          return acc + curr.question_id + ',';
        }, '');
        question_ids = question_ids.substring(0, question_ids.length - 1);

        this.questionEdit();
      },
      sortOptionChange() {},
      // 标题修改
      baseInfoChange(paramName) {
        const obj = {};
        obj[paramName] = this[paramName];
        this.questionEdit('base');
      },
      // 短信验证开关
      async phoneSwitchChange() {
        let isConfirm = true;
        let userId = this.$parent.userId;
        if (!this.curQaInfo.phoneValide) {
          await this.$vhConfirm(
            '关闭后，无需提交手机验证码，将无法保证手机号的准确性，是否关闭？',
            '提示',
            {
              confirmButtonText: '仍然关闭',
              cancelButtonText: '暂不关闭',
              closeOnClickModal: false,
              /* center: true, */
              customClass: 'zdy-message-box',
              lockScroll: false,
              roundButton: true,
              cancelButtonClass: 'zdy-confirm-cancel'
            }
          )
            .then(() => {
              if (this.signUpPageType === 'webinar') {
                this.$vhall_paas_port({
                  k: 100139,
                  data: {
                    business_uid: userId,
                    user_id: '',
                    webinar_id: this.webinarOrSubjectId,
                    refer: '',
                    s: '',
                    report_extra: {},
                    ref_url: '',
                    req_url: ''
                  }
                });
              }
            })
            .catch(() => {
              isConfirm = false;
              this.curQaInfo.phoneValide = true;
            });
        } else {
          if (this.signUpPageType === 'webinar') {
            this.$vhall_paas_port({
              k: 100080,
              data: {
                business_uid: userId,
                user_id: '',
                webinar_id: this.webinarOrSubjectId,
                refer: '',
                s: '',
                report_extra: {},
                ref_url: '',
                req_url: ''
              }
            });
          }
        }
        if (!isConfirm) return false;
      },
      // 邮箱验证开关
      async emailSwitchChange() {
        let isConfirm = true;
        let userId = this.$parent.userId;
        if (!this.curQaInfo.emailValide) {
          await this.$vhConfirm(
            '关闭后，无需提交邮箱验证码，将无法保证邮箱的准确性，是否关闭？',
            '提示',
            {
              confirmButtonText: '仍然关闭',
              cancelButtonText: '暂不关闭',
              closeOnClickModal: false,
              /* center: true, */
              customClass: 'zdy-message-box',
              lockScroll: false,
              roundButton: true,
              cancelButtonClass: 'zdy-confirm-cancel'
            }
          )
            .then(() => {
              if (this.signUpPageType === 'webinar') {
                this.$vhall_paas_port({
                  k: 100139,
                  data: {
                    business_uid: userId,
                    user_id: '',
                    webinar_id: this.webinarOrSubjectId,
                    refer: '',
                    s: '',
                    report_extra: {},
                    ref_url: '',
                    req_url: ''
                  }
                });
              }
            })
            .catch(() => {
              isConfirm = false;
              this.curQaInfo.emailValide = true;
            });
        } else {
          if (this.signUpPageType === 'webinar') {
            this.$vhall_paas_port({
              k: 100080,
              data: {
                business_uid: userId,
                user_id: '',
                webinar_id: this.webinarOrSubjectId,
                refer: '',
                s: '',
                report_extra: {},
                ref_url: '',
                req_url: ''
              }
            });
          }
        }
        if (!isConfirm) return false;
      },
      // 是否必填开关
      requiredSwitchChange(question) {
        this.questionEdit();
      },
      //删除题型
      delQa(id, index, reporType) {
        let userId = this.$parent.userId;
        if (this.signUpPageType === 'webinar') {
          this.$vhall_paas_port({
            k: reporType + 3,
            data: {
              business_uid: userId,
              user_id: '',
              webinar_id: this.webinarOrSubjectId,
              refer: '',
              s: '',
              report_extra: {},
              ref_url: '',
              req_url: ''
            }
          });
        }
        this.renderQuestion.splice(index, 1);
        this.$emit('delQa', null);
        this.initCurQaInfo();
        this.questionEdit();
      },
      //选中题目
      choseQa(info) {
        if (this.signUpSwtich) {
          console.log(info, '=======================');
          this.curQaInfo = info || {};
          this.$emit('choseQa', info);
        }
      },
      handleCheckAllChange(val) {
        console.log(val);
        this.checkedOptions = val ? this.originOptions.map(e => e.option_key) : [];
        this.isIndeterminate = false;
      },
      handleCheckedOptionsChange(value) {
        let checkedCount = value.length;
        this.checkAllOption = checkedCount === this.originOptions.length;
        this.isIndeterminate = checkedCount > 0 && checkedCount < this.originOptions.length;
      },
      closeOptionDialog() {
        this.dialogOptionVisible = false;
        this.originOptions = [];
        this.checkedOptions = [];
      },
      handlerOptionConfirm() {
        if (this.checkedOptions.length === 0) {
          this.$vhMessage.warning('请至少设置一个选项');
          return;
        }
        let res = [];
        for (let i = 0; i < this.originOptions.length; i++) {
          for (let j = 0; j < this.checkedOptions.length; j++) {
            if (this.originOptions[i].option_key == this.checkedOptions[j]) {
              res.push(this.originOptions[i]);
              continue;
            }
          }
        }
        console.log(res, this.originOptions);
        this.$emit('upCurOption', {
          checked: res,
          originOptions: this.originOptions,
          curQaInfo: this.curQaInfo
        });
        this.dialogOptionVisible = false;
        this.originOptions = [];
        this.checkedOptions = [];
      }
    }
  };
</script>

<style lang="less" scoped>
  @import '~@/common/css/other/variables.less';
  .privacy-switch {
    padding: 16px 0 0 0;
  }
  .fieldSetBox {
    width: 100%;
    ::v-deep .intro-input .vh-textarea__inner {
      max-height: 200px;
    }

    ::v-deep .vh-checkbox__label {
      width: calc(100% - 24px);
    }
    ::v-deep .vh-radio__label {
      width: calc(100% - 24px);
      display: inline-block;
    }
  }
  .viewItem {
    border-radius: 4px;
    margin-bottom: 16px;
    border: 1px solid #ffffff;
    position: relative;
    &.active {
      border: 1px solid #fb2626;
    }

    .banner {
      font-size: 14px;
      color: #595959;
      display: flex;
      align-items: center;
      padding-bottom: 10px;
      .banner-switch {
        margin: 0 4px 0 8px;
      }
    }

    .disable_wrap {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.5);
    }
    ::v-deep .avatar-uploader {
      .mask {
        top: 0;
      }
    }
    &.viewItemHover:hover {
      border-color: #fb3a32;
    }

    &.privacyItem {
      .radioInput {
        margin-bottom: 10px;
      }
    }
    .header-img-tip {
      display: block;
      padding-top: 5px;
      font-size: 14px;
      color: #999999;
    }
    .label {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #1a1a1a;
      margin-bottom: 10px;
      line-height: 24px;
      // text-indent: 8px;
      .titleInput {
        font-size: 16px;
      }
    }
    .noFull {
      width: calc(100% - 40px);
    }
    padding: 20px 24px;
    background: #fff;
    .vh-checkbox-group {
      width: 100%;
      padding-left: 0px;
      .vh-checkbox {
        display: block;
        margin-top: 10px;
        margin-right: 0px;
      }
    }
    .vh-radio-group {
      width: 100%;
      padding-left: 0px;
      .vh-radio {
        display: block;
        margin-bottom: 10px;
        margin-right: 0px;
        // &:first-child {
        //   margin-top: 0;
        // }
      }
    }

    .other-checkbox {
      .other-input {
        margin-top: 10px;
      }
    }
    .vh-radio  {
      .other-input {
        margin-top: 10px;
      }
    }
    .bottomBtn {
      margin-top: 9px;
      text-align: right;
      overflow: hidden;
      .clear_both {
        overflow: hidden;
        margin-bottom: 10px;
      }
      .font_set {
        font-size: 14px;
        color: #666;
      }
      .addBtn {
        float: left;
        i {
          margin-left: 0px;
          color: #3562fa;
          font-weight: bold;
          font-size: 14px;
        }

        .line {
          width: 2px;
          height: 13px;
          background: #999999;
          display: inline-block;
          margin: 0 12px;
          vertical-align: middle;
        }
        &.isoverflow {
          i {
            color: #3c3c3c;
          }
        }
      }
      .moveBtn {
        cursor: move;
      }
      i {
        font-size: 18px;
        color: #666666;
        margin-left: 16px;
        cursor: pointer;
      }
      .controlBtnBox {
        display: inline-flex;
        height: 20px;
        line-height: 20px;
        float: right;
        i:hover {
          color: #1a1a1a;
        }
      }
      .swtich {
        vertical-align: text-top;
        margin-left: 16px;
        float: right;
      }
    }
    .selectInput {
      margin-bottom: 10px;
    }
    .regionalInput {
      width: calc(100% - 50px);
    }
    .regionalBtn {
      margin-left: 10px;
    }
    // ::v-deep .box .avatar {
    //   width: auto;
    // }
  }
  .sureBtn {
    background: none;
    text-align: center;
    border: 0;
    .vh-button {
      width: 160px;
    }
  }
  .flip-list-move {
    transition: transform 0.5s;
  }
  .no-move {
    transition: transform 0s;
  }
  .ghost {
    opacity: 0.5;
    background: #fafafa;
  }
  .list-group {
    min-height: 20px;

    .filed-item-drag-group{
      ::v-deep{
        .filed-item-drag-group__item{
          &.vh-radio,
          &.vh-checkbox,
          .filed-item-drag-group__item-input{
            padding-left: 24px;
          }
          &:hover{
            .sort-item,
            .sort-item2{
              display: flex;
              align-items: center;
            }
          }

          .sort-item,
          .sort-item2{
            cursor: move;
            position: absolute;
            height: 40px;
            //padding-top: 5px;
            //box-sizing: border-box;
            z-index: 2;
            left: 0;
            display: none;
            width: 24px;
            margin-top: -1px;
          }

          .filed-item-drag-group__item-input{
            margin-bottom: 10px;
          }
        }
      }

    }
  }
  .list-group-item {
    cursor: move;
  }
  .list-group-item i {
    cursor: pointer;
  }
  .radioInput,
  .selectInput {
    &:hover .removeIcon {
      display: inline-block;
    }
  }
  .removeIcon {
    font-size: 16px;
    color: #666666;
    vertical-align: middle;
    display: none;
    cursor: pointer;
    margin-right: 10px;
  }
  .previewPrivacy {
    font-size: 14px;
    p {
      color: #262626;
    }

    &-content {
      display: flex;
      .checkbox {
        padding-right: 8px;
        margin-top: 3px;
      }
      .pre-html {
        flex: 1;
        line-height: 22px;
        font-size: 14px;
        color: #595959;
        ::v-deep a,
        a:active,
        a:visited,
        a:hover {
          color: #3562fa;
        }
      }
    }

    p {
      margin: 16px 0 8px 0;
      align-items: flex-start;
      width: 100%;
      white-space: normal;
      ::v-deep a {
        color: #3562fa;
        &:link {
          color: #3562fa;
        }
        &:active {
          color: #3562fa;
        }
      }
    }
    ::v-deep .vh-checkbox {
      display: block;
    }
    pre {
      white-space: pre-wrap;
      word-wrap: break-word;
    }
  }
</style>
<style lang="less">
  .privacy-switch {
    .vh-switch__label {
      color: #8c8c8c !important;
    }
    .vh-switch__label.is-active {
      color: #595959 !important;
    }
  }
  .fieldSetBox .list-group .viewItem {
    .titleArea {
      width: 100%;
      .txtError {
        color: #fb2626;
        font-size: 12px;
        margin-top: 4px;
      }
    }
    .subjectError > .radioInput > .vh-input__inner {
      border: 1px solid #fb2626;
    }
  }

  .vh-input--limit .vh-input__inner {
    padding-right: 76px;
  }
  .vh-button--text {
    color: #3562fa;
  }
  .choseOptionDialog {
    .vh-dialog__body {
      .vh-checkbox-group .vh-checkbox {
        .vh-checkbox__label {
          width: 100%;
        }
      }
      .radioInput {
        margin: 5px 0;
      }
      .moveBtn {
        font-size: 20px;
        margin-left: 6px;
      }
      .list-group {
        max-height: 400px;
        overflow-y: auto;
        overflow-x: hidden;
        .inputGroup {
          display: flex;
          align-items: center;
          width: 100%;
          .inputs {
            .radioInput {
              width: 330px;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
</style>
