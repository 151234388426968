<!-- 报名表单，两个地方使用：
  1. 直播列表>直播详情>报名表单
  2. 专题列表>专题详情>观看限制 （选择权限：统一报名表单，各直播自己的失效）
-->
<template>
  <div
    :class="[
      'signup-main',
      { 'subject-signup': signUpPageType === 'subject', 'tab-user': tabType === 'user' }
    ]"
  >
    <div class="head">
      <pageTitle pageTitle="报名表单">
        <!-- 活动才有这个报名表单的开关，专题没有 -->
        <div class="switchBox" v-if="signUpPageType === 'webinar'">
          <vh-switch
            class="swtich"
            v-model="signUpSwtich"
            active-color="#FB3A32"
            inactive-color="#CECECE"
            @change="handleChangeSwitchRegForm"
            active-text=""
          ></vh-switch>
          <span class="sign-switch-desc">
            {{ signUpSwtich ? '已开启' : '开启后' }}，若直播关联专题，以专题鉴权为准。
            <a href="javascript:void(0)" @click="showDetailDialog">查看详情</a>
          </span>
          <span class="openSubjectList" v-if="subjectInfo && subjectInfo.subject_verify == '2'">
            |
            <a href="javascript:void(0)" @click="openSubjectList">查看专题报名用户</a>
          </span>
        </div>
        <!-- 右侧分享、预览按钮 -->
        <div class="headBtnGroup">
          <vh-button
            round
            plain
            size="medium"
            class="transparent-btn"
            v-preventReClick
            @click.prevent.stop="openDialog('share')"
          >
            分享
          </vh-button>
          <vh-button
            type="primary"
            round
            size="medium"
            v-preventReClick
            @click.prevent.stop="showSignUp"
          >
            预览
          </vh-button>
        </div>
      </pageTitle>
      <div class="signup-main-body">
        <div class="signup-main-center">
          <!-- tab切换 -->
          <div id="signTabsDom" class="signup-tabs-layout">
            <vh-tabs v-model="tabType" @tab-click="handleTabClick" :class="[menuBarFixed]">
              <vh-tab-pane label="表单设置" name="form"></vh-tab-pane>
              <vh-tab-pane label="报名用户" name="user"></vh-tab-pane>
            </vh-tabs>
          </div>

          <!-- 中英文切换，这里分开写单独控制，因为"预览"功能非from tab签时也需要能正确的显示表单的内容 -->
          <div class="pane-group-hd" v-if="isBilingual">
            <!-- 表单设置中英文切换 -->
            <vh-radio-group
              v-show="tabType === 'form'"
              v-model="languageTypeOfForm"
              @change="val => handleLanguageTypeChange('form', val)"
              text-color="#fff"
              fill="#FB3A32"
            >
              <vh-radio-button :label="1">中文设置</vh-radio-button>
              <vh-radio-button :label="2">英文设置</vh-radio-button>
            </vh-radio-group>
            <!-- 报名用户共用的中英文切换 -->
            <vh-radio-group
              v-show="tabType === 'user'"
              v-model="languageTypeOfUser"
              @change="val => handleLanguageTypeChange('user', val)"
              text-color="#fff"
              fill="#FB3A32"
            >
              <vh-radio-button :label="1">中文用户</vh-radio-button>
              <vh-radio-button :label="2">英文用户</vh-radio-button>
            </vh-radio-group>
          </div>

          <div class="formGroup">
            <!-- 报名表单 -->
            <sign-set-form
              ref="signSetFormDom"
              :webinarOrSubjectId="webinarOrSubjectId"
              :signUpPageType="signUpPageType"
              :signUpSwtich="signUpSwtich"
              :tabType="tabType"
              :currentFormData="currentFormData"
              :isBilingual="isBilingual"
              :languageType="languageTypeOfForm"
              @changeTabsFixed="changeTabsFixed"
              @getQaInfo="getQaInfo"
              @doSave="saveAllData"
              @choseQa="choseQa"
              @delQa="delQa"
              @previewLanguageChange="previewLanguageChange"
              :baseInfo="baseInfo"
            ></sign-set-form>
            <div class="rightSiderBar" :class="[menuBarFixed]" v-if="tabType === 'form'">
              <vh-tabs v-model="tabSet" key="">
                <vh-tab-pane label="全局设置" name="global" :disabled="!signUpSwtich">
                  <div class="group" v-if="signUpPageType === 'subject'">
                    <div class="title main">
                      用户报名时支持选择专题内活动进行报名
                      <vh-tooltip
                        class="item"
                        effect="dark"
                        placement="top-start"
                        :popper-options="{ zIndex: 4000 }"
                      >
                        <div slot="content">
                          1、配置后，用户报名专题时支持自主选择某活动进行报名
                          <br />
                          2、当专题应用到站点管理中时，用户报名时显示的是该活动关联的站点标题
                        </div>
                        <span class="iconfont-v3 saasicon_help_m"></span>
                      </vh-tooltip>
                    </div>
                    <div class="switch">
                      <vh-switch
                        v-model="subject_verify_live"
                        class="switchBox"
                        size="mini"
                      ></vh-switch>
                    </div>
                    <div class="hr"></div>
                  </div>
                  <div class="group flexbox">
                    <span class="title">
                      报名审核
                      <vh-tooltip
                        class="item"
                        effect="dark"
                        placement="top-start"
                        :popper-options="{ zIndex: 4000 }"
                      >
                        <div slot="content">
                          关闭审核后，审核结果将失效，未通过的用户也可进入直播间
                          ，再次开启时将重新生效。
                        </div>
                        <span class="iconfont-v3 saasicon_help_m"></span>
                      </vh-tooltip>
                    </span>
                    <vh-switch
                      class="swtich"
                      v-model="isAudit"
                      active-color="#FB3A32"
                      inactive-color="#CECECE"
                      size="mini"
                      active-text=""
                      :disabled="!signUpSwtich"
                    ></vh-switch>
                  </div>
                  <div class="group data" v-if="isAudit">
                    <vh-radio-group v-model="auditFail" :disabled="!signUpSwtich">
                      <vh-radio :label="0">审核失败用户无感知</vh-radio>
                      <vh-radio :label="1">审核失败显示结果</vh-radio>
                    </vh-radio-group>
                    <div class="" v-if="signUpPageType === 'webinar'">
                      <span class="link" @click="toEMS('sms')">针对审核通过发送短信通知</span>
                    </div>
                    <div class="" v-if="signUpPageType === 'webinar'">
                      <span class="link" @click="toEMS('wechat')">针对审核通过发送公众号通知</span>
                    </div>
                  </div>
                  <div class="group titleGroup">
                    <div class="title">报名按钮文本</div>
                  </div>
                  <div class="group data">
                    <vh-input
                      :maxlength="15"
                      autocomplete="off"
                      show-word-limit
                      v-model="baseInfo.auth_button"
                      class="inputBox"
                      :disabled="!signUpSwtich"
                    ></vh-input>
                  </div>
                  <div class="group titleGroup">
                    <div class="title">报名页按钮文本</div>
                  </div>
                  <div class="group data">
                    <vh-input
                      :maxlength="30"
                      autocomplete="off"
                      show-word-limit
                      v-model="baseInfo.tab_form_title"
                      class="inputBox"
                      :disabled="!signUpSwtich"
                    ></vh-input>
                  </div>
                  <div class="group data">
                    <vh-input
                      :maxlength="30"
                      autocomplete="off"
                      show-word-limit
                      v-model="baseInfo.tab_verify_title"
                      class="inputBox"
                      :disabled="!signUpSwtich"
                    ></vh-input>
                  </div>
                </vh-tab-pane>
                <vh-tab-pane label="题目设置" class="qaSet" name="scope" :disabled="!signUpSwtich">
                  <template
                    v-if="
                      curQaInfo &&
                      curQaInfo.default_type != 1 &&
                      curQaInfo.reqType != 6 &&
                      curQaInfo.reqType != 7
                    "
                  >
                    <div class="group" v-show="showQaTypeFlag">
                      <div class="title main">切换类型</div>
                    </div>
                    <div class="group data" v-show="showQaTypeFlag">
                      <div class="switch">
                        <vh-select
                          v-model="typeGroup"
                          size="small"
                          @change="changeQaType"
                          placeholder="请选择"
                        >
                          <vh-option label="单选" value="radio"></vh-option>
                          <!-- <vh-option label="多选" value="checkBox"></vh-option> -->
                          <vh-option label="下拉选择" value="select"></vh-option>
                        </vh-select>
                      </div>
                    </div>

                    <div class="group flexbox" v-if="curQaInfo.bottomBtn.includes('phoneValid')">
                      <vh-switch
                        class="swtich"
                        @change="phoneSwitchChange"
                        size="mini"
                        v-model="curQaInfo.phoneValide"
                        active-color="#FB3A32"
                        inactive-color="#CECECE"
                        inactive-text="短信验证"
                      ></vh-switch>
                    </div>
                    <div class="group flexbox" v-if="curQaInfo.bottomBtn.includes('emailValid')">
                      <vh-switch
                        class="swtich"
                        @change="emailSwitchChange"
                        size="mini"
                        v-model="curQaInfo.emailValide"
                        active-color="#FB3A32"
                        inactive-color="#CECECE"
                        inactive-text="邮箱验证"
                      ></vh-switch>
                    </div>
                    <div class="group data" v-if="curQaInfo.bottomBtn.includes('phoneValid')">
                      <div class="title">
                        开启后，将对手机号进行国内号段校验及验证码验证（不含港澳台）
                      </div>
                    </div>
                    <div class="group data" v-if="curQaInfo.bottomBtn.includes('emailValid')">
                      <div class="title">开启后，将对邮箱进行验证码验证</div>
                    </div>
                    <div class="group flexbox" v-if="showQaMustFlag">
                      <!-- <div class="title main">必填项</div>
                      <div class="switch">
                        <vh-switch
                          v-model="subject_verify_live"
                          class="switchBox"
                          size="mini"
                        ></vh-switch>
                      </div> -->
                      <vh-switch
                        v-if="curQaInfo.bottomBtn.includes('requireSwtich')"
                        class="swtich"
                        size="mini"
                        v-model="curQaInfo.required"
                        active-color="#FB3A32"
                        :disabled="requireSwtichDisabled"
                        inactive-color="#CECECE"
                        inactive-text="必填项"
                      ></vh-switch>
                    </div>
                  </template>
                  <template v-else>
                    <div class="group">
                      <div class="title noSet">暂无设置内容</div>
                    </div>
                  </template>
                </vh-tab-pane>
              </vh-tabs>
            </div>
          </div>
          <user-manage
            ref="userManageDom"
            v-if="tabType === 'user'"
            :isBilingual="isBilingual"
            :languageType="languageTypeOfUser"
            :signUpPageType="signUpPageType"
            :webinarOrSubjectId="webinarOrSubjectId"
          ></user-manage>
        </div>
      </div>
    </div>
    <section class="subject-save-btn sureBtn" v-if="signUpPageType === 'subject'">
      <vh-button
        :disabled="!signUpSwtich"
        round
        class="length152"
        type="primary"
        v-preventReClick
        size="medium"
        @click="sureQuestionnaire"
      >
        保存
      </vh-button>
    </section>
    <!-- 开播按钮 -->
    <begin-play
      :webinarId="webinarOrSubjectId"
      v-if="$route.query.type != 5 && webinarState != 4 && signUpPageType == 'webinar'"
    ></begin-play>
    <!-- 直播关联专题详情 -->
    <subject-show-dialog
      v-if="signUpPageType === 'webinar'"
      :webinarOrSubjectId="webinarOrSubjectId"
      :signUpPageType="signUpPageType"
      ref="subjectAuth"
      @getSubjectInfo="getSubjectInfo"
    ></subject-show-dialog>
    <vh-dialog
      title="查看专题报名用户"
      :visible.sync="dialogSubjectLivesVisible"
      width="794px"
      custom-class="subjectUserLives"
    >
      <div v-loading="subjectLivesLoading">
        <div class="statistics">
          <div class="info">
            通过专题报名此活动共计报名用户
            <span class="red">{{ statistics.total_num }}</span>
            位，线上报名
            <span class="red">{{ statistics.online_num }}</span>
            位 ，审核通过
            <span class="red">{{ statistics.audit_adopt_num }}</span>
            位
          </div>
          <vh-button type="white-primary" round plain @click.prevent.stop="downloadHandle">
            导出
          </vh-button>
        </div>
        <!-- 有消息内容 -->
        <div class="list-table-panel" v-if="userList.list.length != 0">
          <!-- 表格与分页 -->
          <vh-table
            ref="userLivesTable"
            :data="userList.list || []"
            max-height="386px"
            :header-cell-style="{
              background: '#f7f7f7',
              color: '#666',
              height: '56px'
            }"
          >
            <vh-table-column
              align="left"
              v-for="(item, index) in userTableColumn"
              :key="index"
              :width="item.width"
              :label="item.label"
              :show-overflow-tooltip="!item.customTooltip"
              :fixed="item.key == 'name' ? true : false"
            >
              <template slot-scope="scope">
                <span :class="item.key" @click="doClickRow(scope.row, item)">
                  <template v-if="item.key == 'audit_status'">
                    {{ AuditStatus[scope.row[item.key]] }}
                  </template>
                  <template v-else>{{ scope.row[item.key] }}</template>
                </span>
              </template>
            </vh-table-column>
          </vh-table>
          <SPagination
            :total="userList.total || 0"
            :page-size="query.limit"
            :currentPage="query.pageNumber"
            @current-change="currentChangeHandler"
            @size-change="handleSizeChange"
          ></SPagination>
        </div>
        <!-- 无消息内容 -->
        <null-page
          class="search-no-data"
          :height="0"
          v-if="userList && userList.total === 0"
        ></null-page>
      </div>
    </vh-dialog>
    <vh-dialog
      :visible.sync="dialogLivesVisible"
      width="434px"
      draggable
      :modal="false"
      :part-block="true"
    >
      <div slot="title">&nbsp;</div>
      <SubjectLives
        ref="subjectLives"
        :isBilingual="isBilingual"
        :languageType="languageTypeOfForm"
        :livesOps="livesOps"
        :belongUser="true"
        maxHeight="280px"
        :selectable="false"
        :showCheckbox="false"
        :subject_id="subjectInfo && subjectInfo.subject_id + ''"
      ></SubjectLives>
    </vh-dialog>
  </div>
</template>

<script>
  import PageTitle from '@/components/PageTitle';
  import { sessionOrLocal, debounce } from '@/utils/utils';
  import beginPlay from '@/components/beginBtn';
  import SignSetForm from './signSetForm';
  import UserManage from './userManage';
  import SubjectShowDialog from './subjectShowDialog.vue';
  import NullPage from '../../PlatformModule/Error/nullPage.vue';
  import SubjectLives from '../../SpecialModule/components/subjectLives.vue';
  import { getfiledJson } from './util';
  import { cloneDeep } from 'lodash';

  const AuditStatus = ['审核中', '已通过', '未通过'];
  export default {
    components: {
      PageTitle,
      beginPlay,
      SignSetForm,
      UserManage,
      SubjectShowDialog,
      NullPage,
      SubjectLives
    },
    props: {
      // 是否是双语双流
      isBilingual: {
        type: Boolean,
        default: false
      },
      // 用户报名时是否支持选择专题内活动进行报名
      subject_verify_live_main: {
        type: Boolean,
        default: false
      }
    },
    provide() {
      return {
        subject_verify_live: () => this.subject_verify_live,
        baseInfo_main: () => this.baseInfo,
        typeGroup_main: () => this.typeGroup,
        curQaInfo_main: () => this.curQaInfo
      };
    },
    data() {
      return {
        // 是否开启报名审核: 中英文一致
        isAudit: null,
        auditFail: 0,

        //表单设置中英文切换：1-中文设置， 2-英文设置
        languageTypeOfForm: 1,
        //报名用户中英文切换：1-中文设置， 2-英文设置
        languageTypeOfUser: 1,
        AuditStatus,
        tabType: 'form', // form-表单；user-用户
        signUpSwtich: false,
        baseInfo: {
          theme_color: 'red',
          tab_verify_title: '我已报名',
          tab_form_title: '活动报名',
          auth_button: '立即预约',
          title: '',
          intro: '',
          cover: '',
          audit_fail: 0,
          is_audit: false, //是否开启报名审核
          show_head_img: 1 // 是否显示表单头图：1.是，0.否
        },
        baseInfoOfZhCache: null,
        baseInfoOfEnCache: null,
        userId: '',
        webinarState: JSON.parse(sessionOrLocal.get('webinarState')), // 活动状态
        menuBarFixed: '',
        vm: null,
        resultVo: {},
        signUpPageType:
          window.location.href.indexOf('/live/signup/') != -1 ||
          window.location.href.indexOf('/lives/entryform') != -1
            ? 'webinar'
            : window.location.href.indexOf('/special/viewer/') != -1 ||
              window.location.href.indexOf('/special/entryform') != -1
            ? 'subject'
            : '',
        webinarOrSubjectId:
          window.location.href.indexOf('/live/signup/') != -1
            ? this.$route.params.str
            : window.location.href.indexOf('/special/viewer/') != -1 ||
              window.location.href.indexOf('/lives/entryform') != -1 ||
              window.location.href.indexOf('/special/entryform') != -1
            ? this.$route.params.id || this.$route.params.str
            : '',
        subjectInfo: null,
        dialogSubjectLivesVisible: false,
        dialogLivesVisible: false,
        query: {
          pos: 0,
          limit: 20,
          pageNumber: 1
        },
        userList: {
          total: 0,
          list: []
        },
        baseTableColumn: [
          {
            label: '用户信息',
            key: 'name',
            width: 150
          },
          {
            label: '手机号',
            key: 'phoneTxt',
            width: 150
          },
          {
            label: '邮箱',
            key: 'emailTxt',
            width: 150
          },
          {
            label: '报名方式',
            key: 'entry_source_str',
            width: 100
          },
          {
            label: '报名时间',
            key: 'created_at',
            width: 200
          },
          {
            label: '报名活动数',
            key: 'webinar_num',
            width: 100
          },
          {
            label: '渠道来源',
            key: 'refer',
            width: 'auto'
          },
          {
            label: '审核状态',
            key: 'audit_status',
            width: 100
          }
        ],
        statistics: {},
        livesOps: {},
        formList: [],
        subject_verify_live: false,
        subjectLivesLoading: false,
        tabSet: 'global',
        typeGroup: '',
        curQaInfo: null
      };
    },
    watch: {
      subject_verify_live_main: {
        handler(val) {
          this.subject_verify_live = val;
        },
        immediate: true,
        deep: true
      },
      curQaInfo: {
        handler(val) {
          this.$nextTick(() => {
            this.$refs.signSetFormDom && this.$refs.signSetFormDom.updateInfo(val);
          });
        },
        immediate: true,
        deep: true
      },
      requireSwtichDisabled: {
        handler(val) {
          if (val) {
            this.curQaInfo.required = true;
          }
        }
      },
      isAudit(newVal) {
        if (this.baseInfo) this.baseInfo.is_audit = newVal;
        if (this.baseInfoOfEnCache) this.baseInfoOfEnCache.is_audit = newVal;
        if (this.baseInfoOfZhCache) this.baseInfoOfZhCache.is_audit = newVal;
      },
      auditFail(newVal) {
        if (this.baseInfo) this.baseInfo.audit_fail = newVal;
        if (this.baseInfoOfEnCache) this.baseInfoOfEnCache.audit_fail = newVal;
        if (this.baseInfoOfZhCache) this.baseInfoOfZhCache.audit_fail = newVal;
      }
    },
    computed: {
      userTableColumn() {
        // 开启审核
        if (this.subjectInfo && !this.subjectInfo.is_audit) {
          this.baseTableColumn = this.baseTableColumn.filter(item => {
            return item.key !== 'audit_status';
          });
        }
        if (this.subjectInfo && this.subjectInfo.subject_verify == '2') {
          if (this.subjectInfo.user_choice == '1') {
            return this.baseTableColumn.filter(item => {
              return item.key !== 'created_at';
            });
          } else {
            return this.baseTableColumn.filter(item => {
              return item.key !== 'webinar_num';
            });
          }
        }
        return this.baseTableColumn;
      },
      currentFormData() {
        return {
          language_type: this.baseInfo.language_type,
          webinar_id: this.signUpPageType === 'webinar' ? this.webinarOrSubjectId : '',
          subject_id: this.signUpPageType === 'subject' ? this.webinarOrSubjectId : '',
          form_tab_verify_title: this.baseInfo.tab_verify_title,
          form_tab_register_title: this.baseInfo.tab_form_title,
          auth_button: this.baseInfo.auth_button,
          form_title: this.baseInfo.title,
          form_introduce: this.baseInfo.intro,
          form_cover: this.baseInfo.cover,
          is_audit: this.isAudit ? 1 : 0,
          audit_fail: this.auditFail,
          ques_list: this.baseInfo.ques_list,
          tempQuestList: this.baseInfo.tempQuestList,
          show_head_img: this.baseInfo.show_head_img
        };
      },
      showQaTypeFlag() {
        return (
          this.curQaInfo &&
          (this.curQaInfo.name == 'position' ||
            this.curQaInfo.name == 'industry' ||
            this.curQaInfo.name == 'duty')
        );
      },
      showQaMustFlag() {
        //默认为空 姓名为空 隐私协议为空 活动选择
        return (
          this.curQaInfo &&
          this.curQaInfo.default_type != 1 &&
          this.curQaInfo.reqType != 6 &&
          this.curQaInfo.reqType != 7
        );
      },
      requireSwtichDisabled() {
        if (
          this.curQaInfo &&
          this.curQaInfo.bottomBtn.includes('phoneValid') &&
          this.curQaInfo.phoneValide
        ) {
          return true;
        } else if (
          this.curQaInfo &&
          this.curQaInfo.bottomBtn.includes('emailValid') &&
          this.curQaInfo.emailValide
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    created() {},
    async mounted() {
      this.userId = JSON.parse(sessionOrLocal.get('userId'));
      await this.getFormInfo(1);
      if (this.$route.query.tab == 2) {
        this.tabType = 'user';
      } else {
        this.tabType = 'form';
      }
    },
    methods: {
      choseQa(info) {
        this.curQaInfo = info;
        if (this.showQaTypeFlag) {
          this.typeGroup = info.type;
        } else {
          this.typeGroup = '';
        }
        this.tabSet = 'scope';
      },
      delQa() {
        this.tabSet = 'global';
        this.curQaInfo = null;
      },
      changeQaType(val) {
        console.log(val, 'changeQaType');
      },
      getQaInfo(info, triggerItem) {
        console.log('getQaInfo info:', info, '; triggerItem:', triggerItem);
        if (triggerItem === 'base') {
          // 如果是标题、简介、头图变化，不能更新ques_list
          Object.assign(this.baseInfo, {
            title: info.form_title,
            intro: info.form_introduce,
            cover: info.form_cover
          });
        } else {
          Object.assign(this.baseInfo, {
            title: info.form_title,
            intro: info.form_introduce,
            cover: info.form_cover
          });
          const formList = [];
          const tempQuestList = [];
          for (let index = 0; index < info.ques_list.length; index++) {
            const element = info.ques_list[index];
            if (
              (element.reqType == 0 && element.default_type == 1) ||
              (element.reqType == 1 && element.name == 'company') ||
              (element.reqType == 1 && element.type == 'input') ||
              element.reqType == 7
            ) {
              //姓名  公司 活动报名
              let options = element.nodes && {
                placeholder: element.nodes[0].value
              };
              if (element.reqType == 1 && element.name == 'company') {
                // 公司
                options = Object.assign(options, {
                  type: 7
                });
              }
              const o = {
                id: (element.question_id + '').indexOf('qID') == -1 ? element.question_id : 0,
                type: element.reqType,
                default_type: element.default_type || 0,
                subject: element.label,
                system_type: element.system_type,
                order_num: index + 1,
                is_must: element.required ? 1 : 0,
                options: JSON.stringify(options),
                items: []
              };
              formList.push(o);
              tempQuestList.push(Object.assign({}, o, { id: element.question_id }));
              continue;
            }
            if (element.reqType == 0 && element.default_type == 2) {
              //手机
              let phoneValide = element.phoneValide;
              const o = {
                id: (element.question_id + '').indexOf('qID') == -1 ? element.question_id : 0,
                type: 0,
                default_type: 2,
                subject: element.label,
                system_type: element.system_type,
                order_num: index + 1,
                is_must: element.required ? 1 : 0,
                options: JSON.stringify({
                  open_verify: phoneValide ? 1 : 0,
                  placeholder: element.nodes[0].value
                }),
                items: []
              };
              formList.push(o);
              tempQuestList.push(Object.assign({}, o, { id: element.question_id }));
              continue;
            }
            if (element.reqType == 0 && element.default_type == 3) {
              //邮箱
              let emailValide = element.emailValide;
              const o = {
                id: (element.question_id + '').indexOf('qID') == -1 ? element.question_id : 0,
                type: 0,
                default_type: 3,
                subject: element.label,
                system_type: element.system_type,
                order_num: index + 1,
                is_must: element.required ? 1 : 0,
                options: JSON.stringify({
                  open_verify: emailValide ? 1 : 0,
                  placeholder: element.nodes[0].value
                }),
                items: []
              };
              formList.push(o);
              tempQuestList.push(Object.assign({}, o, { id: element.question_id }));
              continue;
            }
            if (element.reqType == 0 && element.default_type == 4) {
              //性别
              let arr = [];
              element.nodes[0].children.map(e => {
                arr.push({
                  id: (e.item_id + '').indexOf('node_id') == -1 ? e.item_id : 0,
                  ques_id: (e.question_id + '').indexOf('qID') == -1 ? e.question_id : 0,
                  subject: e.value,
                  type: e.type
                });
              });

              const o = {
                id: (element.question_id + '').indexOf('qID') == -1 ? element.question_id : 0,
                type: element.reqType,
                default_type: 4,
                subject: element.label,
                system_type: element.system_type,
                order_num: index + 1,
                is_must: element.required ? 1 : 0,
                options: JSON.stringify({
                  placeholder: element.nodes[0].value
                }),
                items: arr
              };
              formList.push(o);
              tempQuestList.push(Object.assign({}, o, { id: element.question_id }));
              continue;
            }
            if (element.reqType == 5 && element.name == 'regional') {
              //地域
              const o = {
                id: (element.question_id + '').indexOf('qID') == -1 ? element.question_id : 0,
                type: element.reqType,
                default_type: 5,
                subject: element.label,
                system_type: element.system_type,
                order_num: index + 1,
                is_must: element.required ? 1 : 0,
                options: JSON.stringify(element.options),
                items: []
              };
              formList.push(o);
              tempQuestList.push(Object.assign({}, o, { id: element.question_id }));
              continue;
            }
            if (element.type == 'select') {
              //职务 下拉框
              let arr = [];
              let arr2 = [];
              element.nodes.map(e => {
                arr.push({
                  id: (e.item_id + '').indexOf('node_id') == -1 ? e.item_id : 0,
                  ques_id: (e.question_id + '').indexOf('qID') == -1 ? e.question_id : 0,
                  subject: e.value,
                  type: e.type,
                  general_option_key: e.general_option_key
                });
                arr2.push({
                  id: e.item_id,
                  ques_id: e.question_id,
                  subject: e.value,
                  type: e.type,
                  general_option_key: e.general_option_key
                });
              });
              formList.push({
                id: (element.question_id + '').indexOf('qID') == -1 ? element.question_id : 0,
                type: element.reqType,
                default_type: element.default_type || 0,
                subject: element.label,
                general_field_key: element.general_field_key,
                system_type: element.system_type,
                order_num: index + 1,
                is_must: element.required ? 1 : 0,
                options: element.options && JSON.stringify(element.options),
                items: arr
              });
              tempQuestList.push({
                id: element.question_id,
                type: element.reqType,
                default_type: element.default_type || 0,
                subject: element.label,
                general_field_key: element.general_field_key,
                system_type: element.system_type,
                order_num: index + 1,
                is_must: element.required ? 1 : 0,
                options: element.options && JSON.stringify(element.options),
                items: arr2
              });
              continue;
            }
            if (element.type == 'radio' || element.type == 'checkBox') {
              //单选 多选
              let arr = [];
              let arr2 = [];
              element.nodes[0].children.map(e => {
                arr.push({
                  id: (e.item_id + '').indexOf('node_id') == -1 ? e.item_id : 0,
                  ques_id: (e.question_id + '').indexOf('qID') == -1 ? e.question_id : 0,
                  subject: e.value,
                  type: e.other ? 1 : e.type,
                  general_option_key: e.general_option_key
                });
                arr2.push({
                  id: e.item_id,
                  ques_id: e.question_id,
                  subject: e.value,
                  type: e.other ? 1 : e.type,
                  general_option_key: e.general_option_key
                });
              });
              formList.push({
                id: (element.question_id + '').indexOf('qID') == -1 ? element.question_id : 0,
                type: element.reqType,
                default_type: element.default_type || 0,
                subject: element.label,
                system_type: element.system_type,
                order_num: index + 1,
                general_field_key: element.general_field_key,
                is_must: element.required ? 1 : 0,
                options: element.options && JSON.stringify(element.options),
                items: arr
              });
              tempQuestList.push({
                id: element.question_id,
                type: element.reqType,
                default_type: element.default_type || 0,
                subject: element.label,
                system_type: element.system_type,
                order_num: index + 1,
                general_field_key: element.general_field_key,
                is_must: element.required ? 1 : 0,
                options: element.options && JSON.stringify(element.options),
                items: arr2
              });
              continue;
            }
            if (element.reqType == 6 && element.name == 'privacy') {
              //隐私声明
              let arr = [
                {
                  id: (element.question_id + '').indexOf('qID') == -1 ? element.question_id : 0,
                  options: JSON.stringify({
                    content: element.nodes[0].value,
                    color_text: element.nodes[1].value,
                    url: element.nodes[2].value
                  })
                }
              ];
              let arr2 = [
                {
                  id: element.question_id,
                  options: JSON.stringify({
                    content: element.nodes[0].value,
                    color_text: element.nodes[1].value,
                    url: element.nodes[2].value
                  })
                }
              ];
              if (element.nodes.length > 3) {
                element.nodes.map((e, i) => {
                  if (i > 2 && i % 2 == 1) {
                    arr.push({
                      options: JSON.stringify({
                        id: e.privacy_id || 0,
                        privacy_info: e.value,
                        privacy_link: element.nodes[i + 1].value,
                        privacy_id: 0
                      })
                    });
                    arr2.push({
                      options: JSON.stringify({
                        id: e.privacy_id || 0,
                        privacy_info: e.value,
                        privacy_link: element.nodes[i + 1].value,
                        privacy_id: 0
                      })
                    });
                  }
                });
              }
              formList.push({
                id: (element.question_id + '').indexOf('qID') == -1 ? element.question_id : 0,
                type: element.reqType,
                default_type: element.default_type || 0,
                subject: element.label,
                order_num: index + 1,
                is_must: element.required ? 1 : 0,
                options: JSON.stringify(arr),
                items: []
              });
              tempQuestList.push({
                id: element.question_id,
                type: element.reqType,
                default_type: element.default_type || 0,
                subject: element.label,
                order_num: index + 1,
                is_must: element.required ? 1 : 0,
                options: JSON.stringify(arr2),
                items: []
              });
              continue;
            }
          }
          // console.log('getQaInfo formList:', formList);
          this.baseInfo.ques_list = formList;
          this.baseInfo.tempQuestList = tempQuestList;
        }
      },
      // 设置接口入参，是活动维度 还是 专题维度
      setParamsIdByRoute(params = {}) {
        if (this.signUpPageType === 'webinar') {
          params.webinar_id = this.webinarOrSubjectId;
        } else if (this.signUpPageType === 'subject') {
          params.subject_id = this.webinarOrSubjectId;
        }
        return params;
      },
      // 切换tab
      handleTabClick(tab, event) {
        if (this.signUpPageType === 'webinar') {
          // 如果是活动，左右切换
          let tabCount = this.tabType === 'form' ? 1 : this.tabType === 'user' ? 2 : 0;
          this.$router.push({
            path: `/live/signup/${this.$route.params.str}`,
            query: {
              ...this.$route.query,
              tab: tabCount
            }
          });
        }
      },
      // 中英文切换
      async handleLanguageTypeChange(tabType, val) {
        if (tabType === 'form') {
          // 缓存先前的数据
          const beforeVal = val === 1 ? 2 : 1;
          if (beforeVal == 2) {
            this.baseInfoOfEnCache = cloneDeep(this.baseInfo);
          } else {
            this.baseInfoOfZhCache = cloneDeep(this.baseInfo);
          }
          this.languageTypeOfForm = val;
          if (val == 2 && this.baseInfoOfEnCache) {
            // 选择活动报名的处理
            this.checkAndDoWebinarsQuestion('en', this.baseInfoOfEnCache.tempQuestList);
            this.checkAndDoWebinarsQuestion('en', this.baseInfoOfEnCache.ques_list);

            // 取英文缓存数据
            this.baseInfo = cloneDeep(this.baseInfoOfEnCache);
            this.$nextTick(() => {
              this.$refs.signSetFormDom?.initComp(
                this.baseInfo.tempQuestList ? this.baseInfo.tempQuestList : this.baseInfo.ques_list
              );
            });
          } else if (val == 1 && this.baseInfoOfZhCache) {
            // 选择活动报名的处理
            this.checkAndDoWebinarsQuestion('zh', this.baseInfoOfZhCache.tempQuestList);
            this.checkAndDoWebinarsQuestion('zh', this.baseInfoOfZhCache.ques_list);

            this.checkAndDoWebinarsQuestion('zh', this.baseInfo.tempQuestList);
            this.checkAndDoWebinarsQuestion('zh', this.baseInfo.ques_list);

            // 取中文缓存数据
            this.baseInfo = cloneDeep(this.baseInfoOfZhCache);
            this.$nextTick(() => {
              this.$refs.signSetFormDom?.initComp(
                this.baseInfo.tempQuestList ? this.baseInfo.tempQuestList : this.baseInfo.ques_list
              );
            });
          } else {
            await this.getFormInfo(val);
          }
        } else if (tabType === 'user') {
          this.languageTypeOfUser = val;
        }
      },
      // 切换tab的时候是否定位
      changeTabsFixed(menuBarFixed) {
        this.menuBarFixed = menuBarFixed;
      },
      // 获取表单完整信息
      async getFormInfo(languageType) {
        const params = Object.assign(this.setParamsIdByRoute(), {
          language_type: languageType
        });
        try {
          const [baseRes, questionRes] = await Promise.all([
            this.$fetch('regFromGet', params),
            this.$fetch('regQListGet', params)
          ]);
          if (baseRes.code !== 200 || questionRes.code !== 200) {
            this.messageInfo('获取表单数据出现异常', 'error');
            return;
          }
          // 题目按照 order_num 从小到大排序
          const quesList = questionRes.data.ques_list.sort(compare('order_num')) || [];

          // 因为中英文切换原因，选择活动报名的处理
          this.checkAndDoWebinarsQuestion(languageType === 2 ? 'en' : 'zh', quesList);

          let isAudit = baseRes.data.is_audit ? true : false;
          let auditFail = baseRes.data.audit_fail;
          if (!this.baseInfoOfEnCache && !this.baseInfoOfZhCache) {
            // 首次要从接口赋值
            this.isAudit = isAudit;
            this.auditFail = auditFail;
          }
          // 数据组装
          const bInfo = Object.assign({}, baseRes.data, {
            language_type: languageType,
            is_audit: isAudit,
            ques_list: quesList
          });
          if (bInfo.show_head_img === undefined) {
            bInfo.show_head_img = 1;
          }
          console.log('`````````````````bInfo````````````````', bInfo);
          this.baseInfo = bInfo;
          if (this.signUpPageType === 'subject') {
            // 专题下是否开启，只要引用了报名表单，默认就是开启的
            this.signUpSwtich = true;
          } else {
            this.signUpSwtich = baseRes.data.enable_status == '0' ? false : true;
          }
          this.$nextTick(() => {
            this.$refs.signSetFormDom?.initComp(bInfo.ques_list);
            // 如果当前活动报名开关已开启，题目却没有需要添加
            if (this.subject_verify_live) {
              const idx = bInfo.ques_list.findIndex(item => item.type === 7);
              if (idx === -1) {
                console.log('---this.$refs.signSetFormDom---');
                this.$refs.signSetFormDom?.addFiled({ type: 'webinars' });
              }
            }
          });
        } catch (err) {
          console.log(err);
          this.messageInfo('获取表单数据出现异常', 'error');
        }

        function compare(property) {
          return function (a, b) {
            var value1 = a[property];
            var value2 = b[property];
            return value1 - value2;
          };
        }
      },
      // 预览组件切换中英文时触发
      previewLanguageChange(val) {
        if (this.languageTypeOfForm !== val) {
          this.handleLanguageTypeChange('form', val);
        }
      },
      checkAndDoWebinarsQuestion(lang, quesList = []) {
        // 如果当前活动报名开关未开启，删除所有的活动报名题目
        if (!this.subject_verify_live) {
          for (let i = quesList.length - 1; i > -1; i--) {
            if (quesList[i].type == 7) {
              quesList.splice(i, 1);
            }
          }
        } else {
          // 如果开启了活动报名, 题目中没有需要补上
          const idx = quesList.findIndex(item => item.type === 7);
          if (idx === -1) {
            const element = getfiledJson({ type: 'webinars', lang: lang });
            const ques = {
              id: 0,
              type: element.reqType,
              default_type: element.default_type,
              subject: element.label,
              order_num: 6,
              is_must: element.required ? 1 : 0,
              options: JSON.stringify({
                placeholder: element.nodes[0].value
              }),
              items: [],
              system_type: element.system_type || 0
            };
            const lastQues = quesList[quesList.length - 1];
            if (lastQues?.type == 6) {
              // 如果最后一个题是隐私协议,活动要放在它之前
              quesList.splice(quesList.length - 1, 0, ques);
            } else {
              quesList.push(ques);
            }
          }
        }
        return quesList;
      },
      //文案提示问题
      messageInfo(title, type) {
        if (this.vm) {
          this.vm.close();
        }
        this.vm = this.$vhMessage({
          showClose: true,
          duration: 2000,
          message: title,
          type: type,
          customClass: 'zdy-info-box'
        });
      },
      //确定防抖
      handleChangeSwitchRegForm(value) {
        const oldValue = value;
        this.signUpSwtich = !value;
        this.tabSet = 'global';
        this.curQaInfo = null;
        debounce(async () => {
          await this.switchRegForm(oldValue);
        }, 500);
      },
      // 开启\关闭报名表单开关
      async switchRegForm(value) {
        const url = value ? 'regFromEnable' : 'regFromDisable';
        const behaviour = value ? '开启' : '关闭';
        this.$fetch(url, this.setParamsIdByRoute({}))
          .then(res => {
            if (res.code === 200) {
              if (this.signUpPageType === 'webinar') {
                this.signUpSwtich = value;
                this.$vhall_paas_port({
                  k: value ? 100137 : 100138,
                  data: {
                    business_uid: this.userId,
                    user_id: '',
                    webinar_id: this.webinarOrSubjectId,
                    refer: '',
                    s: '',
                    report_extra: {},
                    ref_url: '',
                    req_url: ''
                  }
                });
              }
              this.messageInfo(`报名表单${behaviour}成功`, 'success');
              // 更新表单组件里的字段展示
              this.$nextTick(() => {
                this.$refs.signSetFormDom &&
                  this.$refs.signSetFormDom.setSwitchStatus(this.signUpSwtich);
              });
            }
          })
          .catch(err => {
            if (err.code == 512800) {
              this.signUpSwtich = !value;
              this.messageInfo('报名表单不能与白名单同时开启', 'error');
            } else {
              this.messageInfo(`报名表单${behaviour}失败`, 'error');
            }
          });
      },
      // 预览
      showSignUp() {
        this.$nextTick(() => {
          this.$refs.signSetFormDom && this.$refs.signSetFormDom.showSignUp();
        });
      },
      // 设置-打开 dialog 方法（通用）
      openDialog(ref) {
        this.$nextTick(() => {
          this.$refs.signSetFormDom && this.$refs.signSetFormDom.openDialog(ref);
        });
      },
      // 打开报名表单详情弹窗说明
      showDetailDialog() {
        this.$refs.subjectAuth.visible = true;
      },
      // 保存
      sureQuestionnaire() {
        if (this.signUpPageType === 'subject') {
          // 专题下，点击保存，后续专属于活动的上报等不触发
          this.saveSubjectViews();
        }
      },
      // 保存观看限制，专题关系
      async saveSubjectViews() {
        this.$fetch('createSubjectVerify', {
          subject_id: this.webinarOrSubjectId,
          subject_verify: 2, // 0无限制 1观看限制 2报名表单 只给控制台使用
          user_choice: this.subject_verify_live ? 1 : 0
        })
          .then(res => {
            // 专题上报
            this.$vhall_paas_port({
              k: '100864',
              data: {
                business_uid: sessionOrLocal.get('userId'),
                user_id: '',
                webinar_id: '',
                subject_id: this.webinarOrSubjectId,
                refer: '',
                s: '',
                report_extra: {},
                ref_url: '',
                req_url: ''
              }
            });
            this.saveAllData();
          })
          .catch(res => {
            console.log(res);
            this.$vhMessage({
              message: res.msg || '保存失败',
              showClose: true,
              type: res.code == 512999 ? 'warning' : 'error',
              customClass: 'zdy-info-box'
            });
          });
      },
      getSubjectInfo(info) {
        this.subjectInfo = info;
      },
      openSubjectList() {
        this.subjectLivesLoading = true;
        this.dialogSubjectLivesVisible = true;
        this.getUserSubjectInfo();
        this.initQueryUserList();
      },
      initQueryUserList() {
        this.query.pos = 0;
        this.query.pageNumber = 1;
        // this.query.limit = 20;
        // 表格切换到第一页
        this.$nextTick(() => {
          if (this.$refs.userLivesTable && this.$refs.userLivesTable.pageInfo) {
            this.$refs.userLivesTable.pageInfo.pageNum = 1;
            this.$refs.userLivesTable.pageInfo.pos = 0;
          }
          this.getUserSubjectList();
        });
      },
      // 获取列表数据
      getUserSubjectList(row) {
        if (row) {
          this.query.pos = row.pos;
          this.query.pageNumber = row.pageNum;
          this.query.limit = row.limit;
        }
        this.$fetch(
          'userRegistrationList',
          this.$params({
            subject_id: this.subjectInfo.subject_id,
            subject_webinar_id: this.subjectInfo.webinar_id,
            ...this.query
          })
        )
          .then(res => {
            if (res && res.code === 200 && res.data && res.data.list) {
              res.data.list.map(item => {
                item.entry_source_str =
                  item.entry_source == 2 || item.entry_source == 3 ? '导入' : '线上报名';
                item.is_enter_str = item.is_enter == 1 ? '是' : '否';
                item.name = item.name || '--';
                item.phone = item.phone;
                item.email = item.email;
                item.phoneTxt = item.phone || '--';
                item.emailTxt = item.email || '--';
                item.created_at = item.created_at || '--';
                item.refer = item.refer || '--';
                item.watch_duration = item.watch_duration || '--';
                item.audit_time = item.audit_time || '--';
              });
              this.userList = res.data;
            } else {
              this.userList = {
                total: 0,
                list: []
              };
            }
            // this.subjectLivesLoading = false;
          })
          .catch(e => {
            console.log(e);
            this.userList = {
              total: 0,
              list: []
            };
            // this.subjectLivesLoading = false;
          });
      },
      // 获取数据
      getUserSubjectInfo() {
        this.$fetch('getStatistics', {
          subject_id: this.subjectInfo.subject_id,
          subject_webinar_id: this.subjectInfo.webinar_id
        }).then(res => {
          if (res && res.code === 200) {
            this.statistics = res.data;
          }
          this.subjectLivesLoading = false;
        });
      },
      openLives(info) {
        this.livesOps = {
          phone: info.phone,
          sign_up_email: info.email,
          subject_user_id: info.reg_id
        };
        if (this.subjectInfo.webinar_id) {
          this.livesOps.subject_webinar_id = this.subjectInfo.webinar_id;
        }
        this.dialogLivesVisible = true;
        this.$nextTick(() => {
          this.$refs.subjectLives.initComp();
        });
        console.log(info);
      },
      // 导出报名用户
      downloadHandle() {
        let params = {
          subject_id: this.subjectInfo.subject_id,
          subject_webinar_id: this.subjectInfo.webinar_id
        };
        this.$fetch('exportForm', {
          ...params
        }).then(res => {
          if (this.vm) {
            this.vm.close();
          }
          this.messageInfo(
            `导出申请成功，${
              this.$store.state.permissionValue.custom_download_toast || '请去下载中心下载'
            }`,
            'success'
          );
          this.$EventBus.$emit('saas_vs_download_change');
        });
      },
      // 跳转短信通知配置
      toEMS(type) {
        const { href } = this.$router.resolve({
          path: `/live/msgNotification/${this.$route.params.str}?type=${type}`
        });
        window.open(href, '_blank');
      },
      saveAllData(options = {}) {
        let valid = true;
        console.log('saveAllData options:', options);
        for (let i = 0; i < this.baseInfo.ques_list.length; i++) {
          const element = this.baseInfo.ques_list[i];
          if (!element.subject) {
            /* this.$vhMessage({
              message: '请输入题目内容',
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            }); */
            return false;
          } else {
            element.subject = element.subject.trim();
          }
          if (element.options && typeof element.options == 'string') {
            // element.subject = element.subject.trim();
            let options = JSON.parse(element.options);
            if (options && options.placeholder) {
              options.placeholder = options.placeholder.trim();
            }
            element.options = JSON.stringify(options);
          }
          if (element.options && typeof element.options == 'object') {
            for (let index = 0; index < element.options.length; index++) {
              if (element.options[index].options) {
                let options = JSON.parse(element.options[index].options);
                if (options.content) {
                  options.content = options.content.trim();
                }
                if (options.url) {
                  options.url = options.url.trim();
                }
                if (options.privacy_link) {
                  options.privacy_link = options.privacy_link.trim();
                }
                element.options[index].options = JSON.stringify(options);
              }
            }
          }
          for (let index = 0; index < element.items.length; index++) {
            element.items[index].subject = element.items[index].subject.trim();
          }
          if (element.system_type == 10 || element.system_type == 11 || element.system_type == 12) {
            if (element.items.length == 0) {
              const types = {
                10: '职位',
                11: '行业',
                12: '职务'
              };
              this.$vhMessage({
                message: `请至少设置一个${types[element.system_type]}选项`,
                showClose: true,
                // duration: 0,
                type: 'error',
                customClass: 'zdy-info-box'
              });
              return false;
            }
          }
          if (element.default_type == 2 || element.default_type == 3) {
            if (element.is_must == 1) {
              valid = false;
            }
          }
        }
        if (valid) {
          this.$vhMessage({
            message: `手机号和邮箱至少要保留一个，且为必填项`,
            showClose: true,
            // duration: 0,
            type: 'error',
            customClass: 'zdy-info-box'
          });
          return false;
        }

        const params = Object.assign(
          this.currentFormData,
          {
            ques_list:
              typeof this.currentFormData.ques_list === 'string'
                ? this.currentFormData.ques_list
                : JSON.stringify(this.currentFormData.ques_list)
          },
          options
        );
        delete params.tempQuestList;

        this.$fetch('regQSave', params).then(res => {
          this.tabSet = 'global';
          this.curQaInfo = null;
          this.initCurQaInfo();
          if (res && res.code === 200) {
            this.$vhMessage({
              message: `保存成功`,
              showClose: true,
              // duration: 0,
              type: 'success',
              customClass: 'zdy-info-box'
            });
            this.getFormInfo(this.languageTypeOfForm);
          } else {
            this.$vhMessage({
              message: res.msg || '保存失败',
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
          }
        });
      },
      // 页码改变按钮事件
      currentChangeHandler(current) {
        this.query.pageNumber = current;
        this.query.pos = parseInt((current - 1) * this.query.limit);
        this.getUserSubjectList();
      },
      // 每页显示个数选择器的选项设置 改变按钮事件
      handleSizeChange(current) {
        this.query.pageNumber = 1;
        this.query.limit = current;
        this.query.pos = 0;
        this.getUserSubjectList();
      },
      doClickRow(row, info) {
        console.log(row, info);
        if (info.key == 'webinar_num') {
          this.openLives(row);
        }
      },
      phoneSwitchChange(val) {
        console.log(val);
        this.$nextTick(() => {
          this.$refs.signSetFormDom && this.$refs.signSetFormDom.phoneSwitchChange();
        });
      },
      emailSwitchChange(val) {
        console.log(val);
        this.$nextTick(() => {
          this.$refs.signSetFormDom && this.$refs.signSetFormDom.emailSwitchChange();
        });
      },
      initCurQaInfo() {
        this.$nextTick(() => {
          this.$refs.signSetFormDom && this.$refs.signSetFormDom.initCurQaInfo();
        });
      }
    }
  };
</script>

<style lang="less" scoped>
  .signup-main {
    .isTabFixedBottom {
      position: fixed !important;
      z-index: 1000;
      top: 60px;
      background: #ffffff;
      width: 100%;
    }
    &.subject-signup {
      .titleBox {
        padding-top: 16px;
        margin-bottom: 24px;
        line-height: 36px;
        .fieldSetBox {
          margin-left: 0;
        }
      }
      ::v-deep .viewItem {
        // margin-bottom: 0;
        // padding: 24px 24px;
        .label {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          margin-bottom: 16px;
        }
        .bottomBtn {
          margin-top: 8px;
        }
      }
      .signup-tabs-layout {
        // background: #f7f7f7;
        border-radius: 4px 4px 0 0;
        .isFixed {
          // background: #f7f7f7;
        }
      }
      .settingBox {
        .rightView {
          // background: #ffffff;
          padding-top: 0;
          .fieldSetBox {
            margin-left: 0;
          }
        }
      }
    }
  }
  .signup-main-body {
    display: flex;
    justify-content: space-between;
  }
  .signup-main-center {
    min-height: 612px;
    background: #ffffff;
    width: 100%;
    border-radius: 4px 4px 0 0;
    .signup-tabs-layout {
      .isFixed {
        width: 1024px;
        background: #ffffff;
        position: fixed !important;
        top: 56px;
        z-index: 9;
      }
      .isFixedBottom {
        width: 100%;
        background: #ffffff;
        position: fixed !important;
        z-index: 1000;
        top: 60px;
        section {
          &.block {
            display: none;
          }
        }
      }
    }

    .formGroup {
      display: flex;
      justify-content: space-between;
      background: #f7f7f7;
      // position: relative;
      .rightSiderBar {
        width: 220px;
        min-height: calc(100vh - 213px);
        background: #ffffff;
        padding: 16px 20px;
        .group {
          overflow: hidden;
          &.flexbox {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          &.data {
            padding: 12px 0;
          }
          &.titleGroup {
            margin-top: 16px;
          }
          .vh-radio {
            margin-bottom: 8px;
          }
          .link {
            color: #3562fa;
            font-size: 13px;
            line-height: 22px;
            cursor: pointer;
          }
          .title {
            font-size: 14px;
            line-height: 22px;
            color: #595959;
            &.main {
              color: #262626;
              .iconfont-v3 {
                color: #666;
              }
            }
            &.noSet {
              color: #8c8c8c;
            }
          }
          .switch {
            overflow: hidden;
            .switchBox {
              float: right;
            }
          }
          .hr {
            width: 100%;
            height: 1px;
            background: #f0f0f0;
            margin: 24px 0 16px;
          }
        }

        &.isFixed {
          position: fixed !important;
          top: 104px;
          z-index: 9;
          height: calc(100vh - 104px);
          right: calc((100% - 224px - 1024px) / 2);
        }
      }
    }

    .pane-group-hd {
      display: block;
      text-align: right;
      background-color: #f7f7f7;
      .vh-radio-group {
        margin: 15px 15px 15px 0;
      }
    }
  }
  .tab-user {
    .signup-main-center {
      width: 100%;
    }
  }

  .swtich {
    vertical-align: sub;
    top: 3px;
  }
  .switchBox {
    display: inline-flex;
    height: 100%;
    .sign-switch-desc {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 27px;
      color: #666666;
      display: inline-block;
      margin: 0 0;
      padding: 0 0;
      height: 20px;
      vertical-align: middle;
      margin-left: 4px;
      a {
        color: #3562fa;
      }
    }
    .openSubjectList {
      line-height: 24px;
      margin-left: 5px;
      color: #3562fa;
      a {
        font-size: 14px;
        color: #3562fa;
      }
    }
  }
  .headBtnGroup {
    float: right;
  }
  .titleBox {
    display: block !important;
    line-height: 40px;
    margin-bottom: 22px;
  }
  .settingBox {
    position: relative;
    width: calc(100% - 240px);
    // background: #fff;
    .isFixed {
      width: 1024px;
      position: fixed !important;
      top: 70px;
      z-index: 999;
    }
    .isFixedBottom {
      position: fixed !important;
      z-index: 999;
      top: 70px;
      section {
        &.block {
          display: none;
        }
      }
    }
    .options {
      width: 170px;
      float: left;
      position: relative;
      min-height: calc(100% - 152px);
      .block {
        font-size: 16px;
        color: #666666;
        height: 40px;
        line-height: 40px;
      }
      .block-bto {
        margin-top: 10px;
      }
      .item {
        font-size: 14px;
        color: #1a1a1a;
        width: fit-content;
        height: 40px;
        line-height: 40px;
        cursor: pointer;
        &:hover {
          color: #fb3a32 !important;
          i {
            color: #fb3a32 !important;
          }
        }
        &.active {
          color: #b3b3b3;
          pointer-events: none;
          i {
            color: #b3b3b3;
          }
        }
        i {
          margin-right: 4px;
          color: #1a1a1a;
        }
        .icon {
          width: 14px;
          height: 14px;
          font-size: 14px;
          display: inline-block;
          margin-right: 8px;
        }
      }
    }
    .rightView {
      display: flex;
      justify-content: center;
      width: calc(100% - 170px);
      float: right;
      position: relative;
    }
    .disable_wrap {
      position: absolute;
      z-index: 1000;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.5);
    }
    .options .disable_wrap {
      background: #f7f7f7;
      opacity: 0.5;
    }
  }
  .subject-save-btn {
    margin-top: 32px;
    text-align: center;
    width: 90%;
  }
</style>
<style lang="less">
  .subject-viewer {
    margin-bottom: -40px;
    .subject-save-btn,
    .subject-viewer_remark {
      z-index: 1000;
      position: relative;
    }
  }
  .signup-main .signup-main-center {
    .vh-tabs__item {
      height: 48px;
      line-height: 48px;
      font-size: 16px;
    }
    .signup-tabs-layout {
      .vh-tabs__header {
        margin: 0;
        padding: 0;
        .vh-tabs__nav-wrap {
          padding: 0 20px;
          &::after {
            background-color: #e6e6e6;
          }
        }
      }
    }
    .settingBox {
      .options {
        height: calc(100vh - 213px);
        min-height: 100%;
        &.isFixed {
          height: calc(100vh - 104px);
          min-height: calc(100vh - 213px);
          overflow-y: auto;
        }
      }
    }
  }
  .signup-main .subjectUserLives {
    .statistics {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;

      .info {
        font-weight: 400;
        font-size: 14px;
        line-height: 26px;
        color: #1a1a1a;
        .red {
          color: #fb2626;
          font-size: 22px;
        }
      }
    }
    .list-table-panel {
      min-height: auto;
      .vh-table {
        .vh-table__body .cell {
          .webinar_num,
          .tool {
            color: #3562fa;
            cursor: pointer;
          }
        }
        .vh-table__row .tool {
          margin-left: 16px;
        }
      }
      .pageBox {
        padding-bottom: 0;
      }
    }
  }
  .signup-main-center .formGroup .rightSiderBar {
    .qaSet {
      .vh-switch {
        height: 30px;
      }
      .vh-switch,
      .vh-switch__label--left {
        width: 100%;
      }
    }

    .vh-tabs .vh-tabs__header,
    .vh-tabs .vh-tabs__content {
      padding: 0;
    }
    .vh-tabs__item {
      height: 25px;
      line-height: 20px;
      font-size: 14px;
      padding-right: 5px;
    }
    .vh-input--limit .vh-input__inner {
      padding-right: 46px;
    }
    .group .vh-select {
      width: 100%;
    }
    .vh-switch {
      .vh-switch__core {
        min-width: 28px;
      }
    }
  }
  .subject-signup {
    .signup-main-center .formGroup .rightSiderBar {
      &.isFixed {
        top: 104px;
        height: calc(100vh - 104px);
        right: calc((100% - 224px - 1024px) / 2 + 40px);
      }
      .vh-tabs__item {
        height: 30px;
        line-height: 30px;
        font-size: 14px;
        padding-right: 5px;
      }
    }
    .signup-main-center .signup-tabs-layout .isFixed {
      width: 944px;
    }
    .settingBox {
      // background: #fff;
      .options {
        width: 120px;
      }
      .rightView {
        width: calc(100% - 120px);
      }
    }
  }
  .hideSidebar .subject-signup .signup-main-center .formGroup .rightSiderBar.isFixed {
    right: calc((100% - 84px - 1024px) / 2 + 40px);
  }
  .hideSidebar .signup-main-center .formGroup .rightSiderBar.isFixed {
    right: calc((100% - 84px - 1024px) / 2);
  }
  @media (min-width: 1920px) {
    .signup-main .signup-main-body .signup-main-center .signup-tabs-layout .isFixed {
      width: 1374px;
    }
    .subject-signup .signup-main-body .signup-main-center .signup-tabs-layout .isFixed {
      width: 1294px;
    }
    .signup-main-body .signup-main-center .formGroup .rightSiderBar {
      &.isFixed {
        right: calc((100% - 224px - 1374px) / 2);
      }
    }
    .subject-signup .signup-main-center .formGroup .rightSiderBar {
      &.isFixed {
        right: calc((100% - 224px - 1374px) / 2 + 40px);
      }
    }
    .hideSidebar .subject-signup .signup-main-center .formGroup .rightSiderBar.isFixed {
      right: calc((100% - 84px - 1374px) / 2 + 40px);
    }
    .hideSidebar .signup-main-center .formGroup .rightSiderBar.isFixed {
      right: calc((100% - 84px - 1374px) / 2);
    }
  }
</style>
