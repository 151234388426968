<template>
  <div>
    <VhallDialog
      title="分享"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      customClass="share-dialog"
      width="640px"
    >
      <div class="content">
        <!-- 开关关闭时的遮罩 -->
        <div v-show="!shareSwtich" class="content-wrap"></div>
        <!-- 设置 -->
        <p class="independentForm">
          <span class="title">
            <span class="content-key">独立表单</span>
            <vh-switch
              class="smallSwtich"
              v-model="shareSwtich"
              @change="switchExtraForm"
            ></vh-switch>
            <vh-tooltip placement="right" v-tooltipMove content="">
              <pre slot="content">
开启独立报名功能后，可为报名表单生成独立的链接地
址。通过分享链接，用户填写报名表单后就能观看直播
和回放。注意：只有活动观看限制设置为“免费”时，
该功能才能生效！</pre
              >
              <i
                style="color: #999999; padding-left: 5px; vertical-align: middle"
                class="iconfont-v3 saasicon_help_m"
              ></i>
            </vh-tooltip>
          </span>
          <vh-button
            size="medium"
            class="length104"
            type="primary"
            v-if="hasChannel"
            :disabled="!shareSwtich"
            @click="choseChannel()"
            round
          >
            选择渠道
          </vh-button>
        </p>

        <div v-if="isBilingual" style="margin-top: 10px">
          <vh-radio-group v-model="languageType" size="small" text-color="#fff" fill="#FB3A32">
            <vh-radio-button :label="1">中文</vh-radio-button>
            <vh-radio-button :label="2">英文</vh-radio-button>
          </vh-radio-group>
        </div>

        <div class="share-content">
          <div class="share-group" v-for="item in channelList" :key="item.id">
            <vh-tooltip
              class="item"
              effect="dark"
              :content="item.channel_name"
              placement="top-start"
              popper-class="pos-abs-set"
            >
              <span class="label share-group-lable-title">
                <span>{{ item.channel_name }}</span>
              </span>
            </vh-tooltip>
            <span class="share-group-url-group group-channel-list">
              <span :class="['share-group-url', `shareUrl${item.channel_code}`]">
                {{ joinUrl(item.channel_code, languageType) }}
              </span>
              <div
                class="share-url-info-copy iconfont-v3 saasline-copy"
                @click="doCopy(joinUrl(item.channel_code, languageType))"
              ></div>
              <vh-tooltip
                class="item"
                effect="light"
                placement="left"
                popper-class="share-tooltip pos-abs-set"
              >
                <div slot="content">
                  <img
                    class="share-img"
                    :src="`http://aliqr.e.vhall.com/qr.png?t=${encodeURIComponent(
                      joinUrl(item.channel_code, languageType)
                    )}`"
                    alt=""
                  />
                </div>
                <div class="share-url-info-copy iconfont-v3 saasicon_gQrcode"></div>
              </vh-tooltip>
              <div
                class="share-url-info-copy iconfont-v3 saasline-delete"
                v-show="item.channel_type != 0"
                @click="channelDel(item.id)"
              ></div>
            </span>
          </div>
        </div>
      </div>
    </VhallDialog>
    <!-- 渠道列表 -->
    <wujieSelectChannel
      ref="selecteChannel"
      :source_type="signUpPageType == 'subject' ? 3 : 1"
      :source_id="webinarOrSubjectId"
      :scene_type="0"
      @selected="eventSelected"
    ></wujieSelectChannel>
  </div>
</template>

<script>
  import { sessionOrLocal, copy } from '@/utils/utils';
  import wujieSelectChannel from '@/components/wujieSelectChannel';
  export default {
    created() {},
    props: {
      baseInfo: {
        type: Object
      },
      // 活动ID 或者 专题ID，跟signUpPageType字段组合使用
      webinarOrSubjectId: {
        type: [Number, String],
        default: 0
      },
      // 报名表单类型：webinar--活动；subject--专题
      signUpPageType: {
        type: [Number, String],
        default: ''
      },
      // 是否是双语双流
      isBilingual: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        dialogVisible: false,
        shareSwtich: true,
        channelList: [],

        languageType: 1
      };
    },
    computed: {
      // 渠道管理
      hasChannel() {
        //  watch.channel 1:渠道管理  0:无权限
        if (JSON.parse(sessionOrLocal.get('SAAS_VS_PES', 'localStorage'))['watch.channel'] == '1') {
          return true;
        } else {
          return false;
        }
      }
    },
    watch: {
      baseInfo: {
        handler(newVal) {
          this.shareSwtich = !!this.baseInfo.open_link;
        },
        deep: true,
        immediate: true
      },
      dialogVisible(val) {
        if (val) {
          this.getChannelTableList();
        }
      }
    },
    components: {
      wujieSelectChannel
    },
    methods: {
      doCopy(info) {
        copy(info);
        this.$vhMessage({
          message: '复制成功',
          showClose: true, // 是否展示关闭按钮
          // duration: 0, // 自动关闭时间，为0永不关闭
          type: 'success', //  提示类型
          customClass: 'zdy-info-box' // 样式处理
        });
      },
      // 独立表单开关事件
      switchExtraForm(value) {
        const val = value ? 1 : 0;
        let userId = this.$parent.userId;
        if (this.signUpPageType === 'webinar') {
          this.$vhall_paas_port({
            k: value ? 100185 : 100186,
            data: {
              business_uid: userId,
              user_id: '',
              webinar_id: this.$parent.webinar_id,
              refer: '',
              s: '',
              report_extra: {},
              ref_url: '',
              req_url: ''
            }
          });
        }
        this.$emit('setBaseInfo', { open_link: val });
      },
      choseChannel() {
        if (this.$refs.selecteChannel) {
          this.$nextTick(() => {
            this.$refs.selecteChannel && this.$refs.selecteChannel.initComp();
          });
        }
      },
      eventSelected(selectedActives) {
        if (selectedActives.length != 0) {
          let ids = selectedActives.map(e => {
            return e.id;
          });

          let params = {
            channel_ids: ids.join(','),
            source_id: this.webinarOrSubjectId || '',
            source_type: this.signUpPageType == 'subject' ? 3 : 1, //来源类型，source_id不为空时必填，1=活动 2=落地页 3=专题
            scene_type: 0
          };
          this.$fetch('bindChannel', this.$params(params), {
            'Content-Type': 'application/json'
          }).then(res => {
            this.getChannelTableList();
          });
        }
      },
      joinUrl(code, languageType) {
        let url = `${process.env.VUE_APP_WAP_WATCH}${
          this.signUpPageType === 'subject' ? '/special/entryform/' : '/lives/entryform/'
        }${this.webinarOrSubjectId}?channel=${code}`;

        if (this.isBilingual) {
          url += `&languageType=${languageType}`;
        }
        return url;
      },
      channelDel(id) {
        this.deleteConfirm(id);
      },
      deleteConfirm(id) {
        this.$vhConfirm('删除后，当前渠道将无法使用，确认删除？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          customClass: 'zdy-message-box',
          lockScroll: false,
          roundButton: true,

          cancelButtonClass: 'zdy-confirm-cancel'
        }).then(() => {
          this.$fetch(
            'unbindChannel',
            this.$params({
              channel_ids: id,
              source_id: this.webinarOrSubjectId,
              source_type: this.signUpPageType == 'subject' ? 3 : 1,
              scene_type: 0
            }),
            {
              'Content-Type': 'application/json'
            }
          )
            .then(res => {
              if (res && res.code === 200) {
                this.$vhMessage({
                  message: `删除成功`,
                  showClose: true,
                  // duration: 0,
                  type: 'success',
                  customClass: 'zdy-info-box'
                });
                // 刷新页面
                this.getChannelTableList();
              }
            })
            .catch(res => {
              this.$vhMessage({
                message: res.msg || `删除失败`,
                showClose: true,
                // duration: 0,
                type: 'error',
                customClass: 'zdy-info-box'
              });
            });
        });
      },
      getChannelTableList() {
        let params = {
          source_id: this.webinarOrSubjectId,
          source_type: this.signUpPageType == 'subject' ? 3 : 1,
          scene_type: 0
        };
        this.$fetch('getChannelListLinked', this.$params(params), {
          'Content-Type': 'application/json'
        }).then(res => {
          this.channelList = res.data.list;
          console.log('?????this.channelList:', this.channelList);
        });
      },
      updateShareSwitch(val){
        this.shareSwtich = val
      }
    }
  };
</script>

<style lang="less" scoped>
  @iconpath: '../../../common/images/icon';
  .share-dialog {
    .content {
      position: relative;
      .content-wrap {
        position: absolute;
        z-index: 2;
        width: 100%;
        bottom: 0;
        height: calc(100% - 40px);
        background: rgba(255, 255, 255, 0.5);
      }

      .independentForm {
        margin-top: 0px;
      }
      p {
        margin-top: 20px;
        overflow: hidden;
        line-height: 34px;
      }
      .content-key {
        color: #1a1a1a;
      }
      .sub {
        color: #999;
        font-size: 12px;
        margin-top: 0;
        // text-indent: 70px;
        text-align: left;
      }

      .icon-content {
        margin-top: 20px;
        .share-div {
          display: inline-block;
          vertical-align: top;
          width: 322px;
          margin-right: 32px;
          .input-with-select {
            background: #f7f7f7;
          }
          .zdy-copy-btn {
            background: #e6e6e6;
            color: #666666;
          }
          // .inputCode {}
        }
      }
    }

    .independentForm {
      .length104 {
        float: right;
      }
    }
    .share-content {
      margin-top: 20px;
      max-height: 320px;
      overflow-y: auto;
      .share-group {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 16px;

        &:last-of-type {
          margin-bottom: 0;
        }

        &-lable-title {
          overflow: hidden;
          margin-right: 7px;
          height: 20px;
          font-size: 14px;
          text-align: right;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #333;
          line-height: 16px;
        }

        &-url-group {
          &.group-channel-list {
            padding-right: 28px;
          }

          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-right: 12px;
          width: 504px;
          height: 40px;
          background: #f9f9fa;
          border-radius: 4px;
          line-height: 36px;
          box-sizing: border-box;
        }

        &-url {
          overflow: hidden;
          padding-left: 12px;
          width: 447px;
          height: 20px;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #333;
          line-height: 16px;
        }

        .share-url-info-copy {
          position: relative;
          float: right;
          margin: 0 0 0 14px;
          color: #999;
          border-radius: 2px;
          cursor: pointer;

          &:hover {
            color: #fb2626;
          }

          .share-img {
            position: absolute;
            width: 50px;
            height: 50px;
          }
        }
      }
    }
  }

  ::v-deep .smallSwtich {
    margin-left: 12px;
  }

  .icons {
    text-align: left;
    width: 322px;
    display: inline-block;
    margin-bottom: 40px;
    li {
      list-style-type: none;
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      i {
        display: inline-block;
        width: 40px;
        height: 40px;
        cursor: pointer;
      }
      &:last-child {
        margin-right: 0;
      }
      &:nth-child(1) {
        i {
          background: url('@{iconpath}/qq.png') center center no-repeat;
          background-size: 100% 100%;
        }
      }
      &:nth-child(2) {
        i {
          background: url('@{iconpath}/weibo.png') center center no-repeat;
          background-size: 100% 100%;
        }
        margin: 0 48px;
      }
      &:nth-child(3) {
        i {
          background: url('@{iconpath}/wechat.png') center center no-repeat;
          background-size: 100% 100%;
        }
      }
    }
    p {
      font-size: 14px;
      font-weight: 400;
      color: #1a1a1a;
      line-height: 20px;
      margin-top: 6px;
    }
  }
  .code-div {
    display: inline-block;
    vertical-align: top;
    text-align: center;
    width: 148px;
    // border: 1px solid #CCCCCC;
    padding-left: 5px;
    .code-img {
      width: 148px;
      height: 148px;
      border: 1px solid #cccccc;
      padding: 8px;
      font-size: 0;
      border-radius: 4px;
    }
    img {
      width: 132px;
      height: 132px;
    }
    .img-code {
      margin-top: 10px;
      line-height: 20px;
    }
  }
</style>
<style lang="less">
  .el-tooltip__popper.is-light.share-tooltip {
    width: 120px;
    height: 120px;
    border: none;
    border-radius: 8px;
    box-shadow: 0 1px 2px rgb(0 0 0 / 20%), 0 3px 6px rgb(0 0 0 / 15.9%);

    .popper__arrow {
      border-left-color: #c4c4c4 !important;
    }
  }
</style>
